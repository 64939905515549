/* Module: S */
main>article {
  padding-left: pxToRem(15);
  padding-right: pxToRem(15);

  h1 {
    font-size: pxToRem(40);
  }

  h2 {
    font-size: pxToRem(28);
  }

  h3 {
    font-size: pxToRem(20);
  }

  h4 {
    font-size: pxToRem(18);
  }

  h5 {
    font-size: pxToRem(14);
  }
}

.long-page main {
  padding-left: pxToRem(20);
  padding-right: pxToRem(20);
  margin-top: pxToRem(-30);
}

.toc-sidebar {
  display: none;
}

.long-doc {
  flex: 1;
}

.toc-lvl1__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.toc-lvl1__list ul {
  list-style-type: none;
  padding-left: pxToRem(20);
}

.toc-lvl1__list li {
  padding-top: pxToRem(10);
}

.toc-lvl1__list a {
  text-decoration: none;
  color: $black-two;
}

li.toc-lvl1__item {
  padding-top: 0;
}

.toc-lvl1__item>a {
  display: none;
}

ul.toc-lvl2__list {
  padding-left: 0;
}

.toc-lvl2__item>a {
  font-weight: 600;
}

.toc-lvl3__item>a {
  font-weight: 600;
}

.toc-sidebar {

  .toc-lvl2__item>a,
  .toc-lvl3__item>a,
  .toc-lvl4__item>a,
  .toc-lvl5__item>a {
    font-weight: normal;

    .icon-arrow-right {
      width: 0.75rem;
      height: 0.5rem;
      margin-left: 0.4rem;
    }
  }
}

.toc-sidebar.toc-sidebar-fixed-header {
  padding-top: 6em;
}