/* Module: S */
.header-quick-links {
    width: auto;
    border-top: none;
    border-bottom: none;
    position: absolute;
    top: pxToRem(42);
    right: pxToRem(200);
}

.header-quick-links::before {
    content: '';
    display: block;
    width: pxToRem(1);
    height: pxToRem(60);
    background-color: $pinkish-grey;
    transform: rotate(30deg);
    position: absolute;
    left: pxToRem(-17);
    top: pxToRem(-17);
}

.header-quick-links__list {
    font-size: pxToRem(12);
    padding: 0;
}

.header-quick-links__item {
    display: inline-block;
    padding-left: pxToRem(10);
    padding-right: pxToRem(10);
}

.header-quick-links__link {
    color: $greyish-brown-three;
    position: relative;
    padding-left: pxToRem(1);
    padding-right: pxToRem(1);
}

.header-quick-links__link--active,
.header-quick-links__link:hover {
    &::after {
        content: '';
        display: block;
        width: 100%;
        height: pxToRem(2);
        background-color: $marigold;
        position: absolute;
        bottom: pxToRem(-3);
        left: 0;
    }
}