/* Module: S */
.home-slider {
  height: pxToRem(420);
}

.home-slider__slides {
  margin-left: auto;
  margin-right: auto;
}

.home-slider__slide {
  position: relative;
  width: pxToRem(1140);
  filter: grayscale(1) brightness(1) contrast(0.9);
}

.home-slider__slide.slick-active {
  filter: grayscale(0) brightness(1) contrast(1);
}

.home-slider__slide-link::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), $black);
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
}

.home-slider__slide-link::after {
  content: "";
  display: block;
  width: pxToRem(1);
  height: pxToRem(140);
  transform: rotate(24deg);
  position: absolute;
  background-color: $white;
  left: pxToRem(104);
  bottom: pxToRem(32);
  @include sq-transition(0.2s all);
}

.home-slider__slide--video {
  &::before {
    top: 50%;
  }
}

.home-slide__image {
  height: pxToRem(420);
}

.home-slide__meta {
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
  width: 100%;
  background-color: transparent;
  padding-left: pxToRem(138);
  padding-bottom: pxToRem(38);
  height: auto;
}

.home-slide__date {
  font-weight: 600;
}

.home-slide__more .icon-arrow-right {
  transition: 0.3s transform ease;
}

.home-slider__slide-link {
  &:hover {
    .home-slide__description {
      @include sq-transition(0.3s all);
      opacity: 1;
      height: auto;
      visibility: visible;
      margin-bottom: pxToRem(33);
    }

    &::after {
      bottom: 27%;
      @include sq-transition(0.3s all);
    }
  }
}

.home-slide__description {
  @include sq-transition(0.3s all);
  opacity: 0;
  visibility: hidden;
  display: block;
  height: 0px;
  font-size: pxToRem(16);
  line-height: pxToRem(24);
  max-width: pxToRem(430);
  overflow: hidden;
}

.home-slider__slide-link:hover .icon-arrow-right {
  transform: translateX(30px);
}

.home-slide__link {
  font-size: pxToRem(32);
  display: block;
  padding-top: pxToRem(2);
  padding-bottom: pxToRem(6);
}

.home-slider .slick-arrow {
  display: block;
  border: none;
  background-color: $marigold;
  width: pxToRem(50);
  height: pxToRem(60);
  position: absolute;
  top: pxToRem(190);
  text-indent: -999em;
  overflow: hidden;
  background-image: url("{{file_dest}}/arrow-left.svg");
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
}

.home-slider .slick-prev {
  left: calc(((100% - 1140px) / 2) - 25px);
}

.home-slider .slick-next {
  right: calc(((100% - 1140px) / 2) - 25px);
  transform: rotate(180deg);
}

.lity .lity-close {
  position: absolute;
  right: pxToRem(-18);
  top: pxToRem(-17);
  &:active,
  &:hover  {
    top: pxToRem(-17) !important;
  }
}

.landing-promo__video {
  .landing-promo--right & {
    order: 2;
  }
}

  .perform-promo__border {
    height: pxToRem(10);
  }