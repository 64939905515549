/* Module: S */
.consultations-detail {

  .consultation {
    
    &__tabs {
      .consultation__wrapper {
        padding-bottom: 0;
      }
    }

    &__wrapper {
      margin-left: auto;
      margin-right: auto;
      padding-left: 0;
      padding-right: 0;
      width: pxToRem(1140);
    }
    
    &__title {
      font-size: pxToRem(50);
      line-height: 1.16;
      width: pxToRem(711);
      margin-bottom: 0;
      margin-top: 0;
    }

    &__tab-titles {
      list-style: none;
      display: block;
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }

    &__tab-title {
      display: inline-block;
      border-bottom: 2px solid $pinkish-grey;
      margin-right: pxToRem(10);
      width: pxToRem(280);
      &:hover,
      &:active,
      &:focus,
      &.tabs__item--active {
        border-bottom: 2px solid $marigold;
      }
    }

    &__tab-btn {
      display: inline-block;
      width: 100%;
    }

    &__detail-description {
      max-width: pxToRem(738);
      font-size: pxToRem(16);
      line-height: 1.5;
      color: $greyish-brown;
    }

    &__meta {
      max-width: pxToRem(930);
    }

    &__meta-request {
      margin-top: pxToRem(40);
    }
  }
  
  .curr-cons__status-box {
    margin: 0;
    margin-top: pxToRem(46);
  }

  .accordion__item-name {
    display: none;
  }

  .accordion__item-content {
    padding-left: 0;
  }

  .tabs__content--active {
    display: block;
  }

  .documents__item { 
    padding: 1.25rem 0.938rem;
    margin-bottom: 0;
    display: block;
  }
  
  .documents__download-link {
    margin-left: pxToRem(44);
  }
  .document__container { 
    margin-top: 0;
    margin-bottom: pxToRem(60);
  }
}