/* Module: S */
.hit-wrapper {
    border-top: pxToRem(1) solid $white-eight;
    background-color: $white-two;
}

.how-it-works {
    padding: pxToRem(20) pxToRem(15) pxToRem(30);
}

.hit__heading {
    text-align: center;
    font-size: pxToRem(34);
    font-weight: 400;
}

.hit__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.hit__item {
    background-color: $white;
    padding: pxToRem(20);
    border-bottom: pxToRem(3) solid $black;
    overflow: auto;
    margin-bottom: pxToRem(20);
}

.hit__category {
    .icon-hit-discussion {
        width: pxToRem(64);
        height: pxToRem(58);
    }

    .icon-hit-doc {
        width: pxToRem(45);
        height: pxToRem(64);
    }

    .icon-hit-follow {
        width: pxToRem(62);
        height: pxToRem(52);
    }
}

.svg-icon.icon-hit-discussion {
}

.hit__title {
    font-weight: 400;
    font-size: pxToRem(28);
    line-height: 1.29;
    color: $black-two;
}

.hit__desc {
    font-size: pxToRem(16);
    color: $greyish-brown;
    line-height: 1.5;
}

.hit__more {
    text-align: right;
    float: right
}

.hit__more .icon-arrow-right {
    width: pxToRem(23);
    height: pxToRem(15);
}
