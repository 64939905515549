/* Module: S */
.landing-promo {
  border-bottom: none;
  padding-bottom: pxToRem(0);
  border-top: pxToRem(80) solid $white-two;
  position: relative;
}

.landing-promo__container {
  display: flex;
  width: pxToRem($container-width-desktop);
  margin-left: auto;
  margin-right: auto;

  .content & {
    width: 100%;
  }
}

.landing-promo .landing-container::after {
  content: "";
  display: block;
  width: pxToRem(1140);
  height: pxToRem(10);
  background-color: $black-two;
  position: absolute;
  bottom: pxToRem(-10);
}

.landing-promo__image {
  position: relative;
  top: pxToRem(-60);
  max-width: pxToRem(555);
  height: auto;
  margin-top: 0;
  align-self: flex-start;
}

.landing-promo--right {
  .landing-promo__image {
    order: 2;
  }
  .landing-promo__meta {
    left: auto;
    order: 1;
    padding-right: pxToRem(60);
    padding-left: 0;
  }

}

.main.content .landing-promo--right {
  .landing-promo__image {
    right: pxToRem(75);
  }

  .landing-promo__desc { 
    padding-right: 0;
  }  

  .landing-promo__meta {
    padding-right: pxToRem(91.2);
  }

  .landing-promo__title {
    padding-right: 0;
  }
}

.landing-promo__meta {
  top: 0;
  width: pxToRem(550);
  padding-top: pxToRem(40);
  padding-left: pxToRem(60);
  
  // style for two column
  .main.content & {
    padding-right: pxToRem(75.2);
    padding-left: pxToRem(24);
  }
}

.landing-promo__title {
  margin-top: 0;
  // style for two column
  .main.content & {
    word-wrap: break-word;
    max-width: pxToRem(450);
  }
}

.landing-promo__desc {
}

.landing-promo__more {
  margin-bottom: pxToRem(60);
  display: inline-block;
}

.landing-promo__more .icon-arrow-right {
  transition: 0.1s transform ease;
}

.landing-promo__more:hover .icon-arrow-right {
  transform: translateX(10px);
}

.svg-icon.icon-arrow-right {
}

.landing-promo__video:hover,
.landing-promo__video:focus,
.landing-promo__video:visited {
  outline: none;
}
