/* Module: S */
.home-scroll-down__wrapper {
    display: block;
    padding-bottom: pxToRem(25);
    padding-top: pxToRem(25);
    text-align: center;
    background-color: $white-two;
}

.home-scroll-down {
    color: $black-four;
    display: block;
    font-size: pxToRem(12);
    font-weight: 600;
    line-height: 2.67;
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    text-transform: uppercase;
}

.home-scroll-down .icon-arrow-bottom {
    display: block;
    height: pxToRem(7);
    margin-left: auto;
    margin-right: auto;
    width: pxToRem(11);
}
