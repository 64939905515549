/* Module: S */
.detailed-guide {

  &.layout-left {
      
      .guide__container {
        @include flexbox();
        @include justify-content(space-between);

        .guide-links {
          width: 30%;
        }

        .guide-content {
          width: 70%;
        }
      }
  }
}