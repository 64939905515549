/* Module: S */
.back-top {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 99;
    
    &.fixed {
        position: fixed;
        -webkit-transform: translateZ(0);
    }

    a {
        text-decoration: none;
        display: block;
        height: pxToRem(40);
        @extend %clearfix;
    }

    .icon-arrow-right {
        fill: $marigold;
        width: pxToRem(40);
        height: pxToRem(40);
        padding: pxToRem(10);
        background-color: $black-two;
        transform: rotate(-90deg);
        display: block;
        float: left;
    }

    span {
        display: none;
    }
}