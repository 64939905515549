/* Module: S */
.home-personas__container {
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    padding-bottom: pxToRem(26);
    max-width: pxToRem(946);
    margin-left: auto;
    margin-right: auto;
}

.home-personas__persona {
    width: pxToRem(458);
    height: pxToRem(270);
    padding-left: pxToRem(208);
    background-color: transparent;
    position: relative;
    padding-top: pxToRem(96);
    text-align: left;

    &:hover {
        background-color: $white;
    }
}

.home-personas__persona::after {
    position: absolute;
    top: pxToRem(40);
    left: pxToRem(174);
    content: '';
    height: pxToRem(188);
    width: pxToRem(1);
    transform: rotate(20deg);
    background-color: $warm-grey-six;
}

.home-personas__role {
    font-size: pxToRem(40);
}

.home-personas__persona .icon-persona {
    position: absolute;
    left: pxToRem(98);
    top: pxToRem(64);
}

.home-personas__persona--employer .icon-home-employer {
    height: pxToRem(50);
    width: pxToRem(70);
}

.home-personas__persona--worker .icon-home-worker {
    height: pxToRem(50);
    width: pxToRem(64);
}

.home-personas__more {
    text-align: left;
    margin-top: pxToRem(11);

}

.home-personas__persona .icon-arrow-right {
    fill: $warm-grey-three;
    width: pxToRem(20);
    height: pxToRem(13);
    transition: .1s transform ease;
}

.home-personas__persona:hover .icon-arrow-right {
    transform: translateX(30px);
    fill: $black;
}
