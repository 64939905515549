/* Module: S */
.s-disputes-panel h1.p-info-panel__heading {
  font-size: 2.1rem;
 }

.p-info-panel {
  width: 100%;
  margin: 0;
  position: relative;
}

@media screen and (min-width: 63.75em) {
  .p-info-panel {
    max-width: 34.5rem;
    width: 48.65%;
  }
}

.p-info-panel::before {
  background-color: $white;
  bottom: 0;
  content: '';
  height: 1.375rem;
  position: absolute;
  width: 1.375rem;
  z-index: 1000;
}

@media screen and (min-width: 63.75em) {
  .p-info-panel::before {
    height: 2.0625rem;
    width: 2.0625rem;
    z-index: 1;
  }
}

.p-info-panel::after {
  border-bottom: 1.375rem solid $white;
  bottom: 0;
  content: '';
  height: 0;
  position: absolute;
  width: 100%;
}

@media screen and (min-width: 63.75em) {
  .p-info-panel::after {
    border-bottom: 2.0625rem solid $white;
  }
}

.p-info-panel:nth-child(odd)::after {
  border-left: 1.375rem solid transparent;
  // left: 1.4375rem;
  left: 0;
}

@media screen and (min-width: 63.75em) {
  .p-info-panel:nth-child(odd)::after {
    border-left: 2.0625rem solid transparent;
    // left: 2.125rem;
    left: 0;
  }
}

@media screen and (min-width: 48.125em) {
  .p-info-panel:nth-child(odd) {
    margin-right: 0.625rem;
  }
}

@media screen and (min-width: 48.125em) {
  .p-info-panel:nth-child(even) {
    margin-left: 0.625rem;
  }
}

.p-info-panel:nth-child(even)::before {
  right: 0;
  z-index: 1;
}

.p-info-panel:nth-child(even)::after {
  border-right: 1.375rem solid transparent;
  // right: 1.4375rem;
  right: 0;
}

@media screen and (min-width: 63.75em) {
  .p-info-panel:nth-child(even)::after {
    border-right: 2.0625rem solid transparent;
    // right: 2.125rem;
    right: 0;
  }
}

@media screen and (min-width: 48.125em) {
  .p-info-panel:nth-child(even) {
    margin-left: 0.625rem;
  }
}

.p-info-panel__link {
  background-position: 50% 50%;
  background-size: cover;
  display: block;
  height: 18.25rem;
  overflow: hidden;
  padding: 1.6875rem;
  position: relative;
  text-decoration: none;
}

@media screen and (min-width: 48.125em) {
  .p-info-panel__link {
    height: 20rem;
  }
}

@media screen and (min-width: 63.75em) {
  .p-info-panel__link {
    padding: 2.5rem;
  }
}

.p-info-panel__link::before {
  background-color: #000;
  content: '';
  height: 100%;
  left: 0;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  width: 100%;
}

.p-info-panel__link:hover::before,
.p-info-panel__link:focus::before {
  background-color: $ancient-gold;
}

.p-info-panel:nth-child(odd) .p-info-panel__link {
  background-image: url("");
}

.p-info-panel:nth-child(even) .p-info-panel__link {
  background-image: url("");
}

.p-info-panel__inner {
  bottom: 3.125rem;
  color: $white-two;
  position: absolute;
}

@media screen and (min-width: 48.125em) {
  .p-info-panel__inner {
    bottom: 0;
    transform: translateY(100%) translateY(-145px);
    transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
    width: calc(100% - 80px);
  }
}

@media screen and (min-width: 82.5em) {
  .p-info-panel__inner {
    transform: translateY(100%) translateY(-170px);
  }
}

@media screen and (min-width: 48.125em) {
  .p-info-panel__link:hover .p-info-panel__inner,
  .p-info-panel__link:focus .p-info-panel__inner {
    transform: translateY(-27px);
  }
}

@media screen and (min-width: 63.75em) {
   .p-info-panel__link:hover .p-info-panel__inner,
   .p-info-panel__link:focus .p-info-panel__inner {
    transform: translateY(-47px);
  }
}

@media screen and (min-width: 48.125em) {
  .p-info-panel:nth-child(even) .p-info-panel__inner {
    text-align: right;
  }
}

/*@media screen and (min-width: 63.75em) {
  .p-info-panel:nth-child(even) .p-info-panel__inner {
    right: 1.6875rem;
  }
}*/

.p-info-panel__heading {
  font-size: 2.0625rem;
  line-height: 1.05;
  margin: 0;
}

@media screen and (min-width: 48.125em) {
  .p-info-panel__heading {
    height: 5rem;
    -webkit-display: flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    max-height: 5rem;
  }
}

@media screen and (min-width: 82.5em) {
  .p-info-panel__heading {
    height: 6.5625rem;
    font-size: 3.125rem;
    margin: 0 -1.875rem 0 0;
    max-height: 6.5625rem;
    overflow-y: hidden;
  }
}

@media screen and (min-width: 48.125em) {
  .p-info-panel:nth-child(even) .p-info-panel__heading {
    margin: 0 -1rem 0 0;
    text-align: right;
  }
}

.p-info-panel__intro {
  display: none;
  color: white !important;
}

@media screen and (min-width: 48.125em) {
  .p-info-panel__intro {
    display: block;
    line-height: 1.5;
    margin-top: 2.8125rem;
    opacity: 0;
    position: relative;
    transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
    visibility: hidden;
  }
  .p-info-panel:nth-child(even) .p-info-panel__intro {
    right: 0;
  }
}

@media screen and (min-width: 48.125em) {
  .p-info-panel__intro::before {
    background-color: $white-two;
    content: '';
    height: 0.4375rem;
    position: absolute;
    top: -1.6875rem;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    width: 0;
  }
  .p-info-panel:nth-child(even) .p-info-panel__intro::before {
    right: 0;
  }
}

@media screen and (min-width: 48.125em) {
   .p-info-panel__link:hover .p-info-panel__intro::before,
   .p-info-panel__link:focus .p-info-panel__intro::before {
    width: 7.375rem;
  }
}

@media screen and (min-width: 48.125em) {
   .p-info-panel__link:hover .p-info-panel__intro,
   .p-info-panel__link:focus .p-info-panel__intro  {
    opacity: 1;
    visibility: visible;
  }
}

body::before {
  background-color: transparent;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}

body.is-infopanel-active {
  overflow: hidden;
}

body.is-infopanel-active::before {
  background-color: rgba(0, 0, 0, 0.65);
  content: '';
  height: 100vh;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.5s ease;
  visibility: visible;
  width: 100vw;
  z-index: 10;
}

.p-info-section {
  background-color: $white-two;
  height: 100vh;
  left: 0;
  max-width: 63.125rem;
  min-height: 100vh;
  opacity: 0;
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  visibility: hidden;
  width: 100%;
  z-index: 10000;
}

.p-info-section:nth-child(2) {
  left: auto;
  right: 0;
  transform: translateX(100%);
}

.p-info-section.is-active {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}

.p-info-section h1 {
  font-size: 2.5rem;
}

.p-info-section__container {
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 2.0625rem;
}

.p-info-section__backto {
  display: flex;
  justify-content: flex-end;
  z-index: 12;
}

@media screen and (min-width: 63.75em) {
  .p-info-section__backto {
    right: -0.625rem;
    position: absolute;
    top: 1.25rem;
  }
  .p-info-section:nth-child(2) .p-info-section__backto {
    left: -0.625rem;
    right: auto;
  }
}

.p-info-section__btn {
  background-color: $white-three;
  padding: 0.75rem 1.25rem;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}

.p-info-section__btn:hover,
.p-info-section__btn:focus {
  background-color: $frangipani;
}

.p-info-section__btn-txt {
  display: inline-block;
  margin-right: 0.625rem;
  position: relative;
  top: -0.125rem;
}

.p-info-section__btn-icon {
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}

.p-info-section__btn:hover .p-info-section__btn-icon,
.p-info-section__btn:focus .p-info-section__btn-icon {
  transform: rotate(180deg);
}

@media screen and (min-width: 63.75em) {
  .p-info-section__content {
    margin-top: 4.8125rem;
    padding: 0 !important;
  }
}

.p-info-section__content-description {
  font-size: 1.375rem;
  line-height: 2rem;
}

@media screen and (min-width: 48.125em) {
  .s-disputes-panel {
    align-content: stretch;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
