/* Module: S */
.c-tab__link {
  height: auto;
  padding: 1.25rem 1.2rem;
}

.c-tab__list {
  @extend %list-reset;
}

@media screen and (min-width: 48.125em) {
  .c-tab__list {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.c-tab__item {
  margin-bottom: 1.875rem !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}
@media screen and (min-width: 48.125em) {
  .c-tab__item {
    max-width: 29.5rem;
    width: calc(50% - 30px);
    padding: 0 0 0 20px;
  }

  .c-tab__item:nth-child(odd) {
    margin-right: 0.9375rem;
  }

  .c-tab__item:nth-child(even) {
    margin-left: 0.9375rem;
    padding: 0 0 0 1.25rem !important;
  }
}

.c-tab__item:before {
  display: none !important;
}

.c-tab__link {
  background-color: $white-four;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 8.375rem;
  justify-content: flex-end;
  padding: 1.25rem 1.875rem;
  text-decoration: none;
  transition: background-color 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}

.c-tab__link:hover,
.c-tab__link:focus {
  //background-color: $frangipani;
  background-color: $marigold-50;
}

.c-tab__link.is-tab-active {
  //background-color: $frangipani;
  background-color: $marigold-50;
}

.js-enabled .c-tab__section {
  display: none;
}

.c-tab__section.is-tab-active {
  display: block;
}

.c-tab__txt {
  color: #363636;
  font-size: 1.125rem;
  margin: 0;
  width: 100%;
}
@media screen and (min-width: 48.125em) {
  .c-tab__txt {
    font-size: 1.25rem;
  }
}

.c-tab__date {
  color: #000;
  //font-family: 'Clarendon-text-pro';
  font-size: 1.5rem;
  width: 100%;
}
