/* Module: S */
.resources-topic {
  &__header {
    text-transform: uppercase;
    font-size: pxToRem(18);
    font-weight: 400;
  }
  

  &__link {
    text-decoration: none;
    background: $white;
    padding: pxToRem(15)  0 pxToRem(15) pxToRem(20);
    display: block;
    position: relative;
    margin-bottom: pxToRem(15);
    color: $black;
    font-size: pxToRem(18);
    padding-right: pxToRem(40);

    .icon-arrow-right {
      height: pxToRem(12);
      width: pxToRem(19);
      position: absolute;
      top: pxToRem(20);
      right: pxToRem(20);
      transition: .2s ease transform;
    }

    &:hover,
    &:focus {
      .icon-arrow-right {
        transform: translateX(10px);
      }
    }
  }
}