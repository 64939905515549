/* Module: S */
.footer-contact {
    order: -1;
    display: flex;
    flex-wrap: wrap;
    line-height: 2.33;
}

.footer-col__header--contact {
    order: 1;
}

.footer-contact__item {
    border: none;
    padding: 0;
    color: $greyish-brown;
    height: auto;
    line-height: 2.33;
    margin-bottom: 0;
    font-size: pxToRem(14);
    position: relative;
    height: 34px;
    display: inline-block;
}

.footer-contact__item:hover::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: pxToRem(1);
    background-color: $greyish-brown;
    position: absolute;
    left: 0;
    // bottom: pxToRem(16);
    // bottom: pxToRem(10);
    bottom: 12px;
}

.footer-contact__item--page {
    order: 2;
    margin-right: pxToRem(120);
}

.footer-contact__item--phone {
    font-size: pxToRem(14);
    order: 3;
    top:pxToRem(-3);
}

.footer-contact__item--email {
    order: 4;
    top:pxToRem(-6);
}

.footer-contact__item--feedback {
    order: 6;
    top:pxToRem(-9);
}

.footer-contact__item-text {
    padding-left: pxToRem(4);
}

.icon-contact-phone {
    width: pxToRem(13);
    height: pxToRem(13);
    top: 0px;
}

.icon-contact-mail {
    width: pxToRem(14);
    height: pxToRem(10);
    top: 0px;
}

.icon-contact-feedback {
    width: pxToRem(13);
    height: pxToRem(13);
    top: 0px;
}

.mobile-share-bar {
    display: none;
}
.desk-share-bar {
    display: block;
    position: relative;
    top:pxToRem(-9);
    height: pxToRem(34);
}
.footer-contact__container {
    order: 2;
    margin-top: pxToRem(-25);
}