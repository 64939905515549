/* Module: S */
.landing-container,
.resources-topic__container {
    width: pxToRem(1140 + 80);
    margin-left: auto;
    margin-right: auto;
    padding-left: pxToRem(40);
    padding-right: pxToRem(40);
}

.landing-container {
  .content & {
    width: 100%;
  }
}