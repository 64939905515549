/* Module: S */
main > article {
    margin-left: auto;
    margin-right: auto;
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
}

main .toc-lvl1__list {
    padding-left: pxToRem(40);
}

.long-page main {
    padding-left: pxToRem(40);
    padding-right: pxToRem(30);
    margin-top: 0;
}

.toc-sidebar {
    background-color: #ffc107;
    padding: 0 pxToRem(20) pxToRem(20) pxToRem(20);
    border-bottom: 0.25rem solid #181818;
    align-self: flex-start;
    order: 2;
    display: block;
    position: sticky;
    width: pxToRem(300);
    min-width: pxToRem(300);
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    margin-right: pxToRem(40);
    margin-left: 0;
  }

.toc,
.main-content .toc {
    padding: 0 0 0 40px;
}

.lhs-toc,
.main-content .lhs-toc {
    background: #fff;
    height: 400px;
    left: 0;
    overflow-y: scroll;
    padding: 0 30px 0 0;
    position: absolute;
    top: 50px;
    width: 286px;
}

.sq-active .lhs-toc,
.sq-active .main-content .lhs-toc {
    display: block;
}

.lhs-toc .toc,
.main-content .lhs-toc .toc {
    margin: 25px 0 0;
    padding: 0;
}
