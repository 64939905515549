/* Module: S */
.content__video {
  &--left,
  &--right {
    display: flex;
    .content__video-col-content > p {
      margin-top: 0;
    }
  }

  &-col-content {
    margin-top: 0;
  }

  &-col-vid,
  &-col-content {
    width: 50%;
  }

  &--centre {
    .content__video-col-vid {
      width: 100%;
    }
    .content__video-col-content { 
      width: 100%;
    }
    & figcaption {
      display: block;
    }
  }

  &--left {
    .content__video-col-content {
      padding-left: pxToRem(40);
    }
  }
  
  &--right {
    .content__video-col-vid {
      order: 2;
    }

    .content__video-col-content {
      order: 1;
      padding-right: pxToRem(40);
    }
  }
}
