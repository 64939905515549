/* Module: S */
.home-search {
    background-color: $white-four;
    margin-top: 0;
    height: pxToRem(75);
}

.home-search__form {
    max-width: pxToRem(1140);
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    height: 100%;
    margin-bottom: pxToRem(5);
}

.home-search__form::after {
    position: absolute;
    bottom: pxToRem(-5);
    height: pxToRem(5);
    left: calc(50%);
    margin-left: -50%;
}

.home-search__label {
    background-color: $white-four;
    width: pxToRem(293);
    font-size: pxToRem(18);
    line-height: pxToRem(75);
    padding-left: pxToRem(20);
}

.home-search__input {
    width: auto;
    flex: 1;
    font-size: pxToRem(18);
    padding-left: pxToRem(20);
}

.home-search__submit {
    width: pxToRem(75);
    height: pxToRem(75);
    margin-top: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
}

.home-search__submit .svg-icon {
    width: pxToRem(25);
    height: pxToRem(25);
}