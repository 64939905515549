/* Module: S */
.perform {

  .content--search,
  &-workshop__title,
  &-workshop__content {
    background: none;
    justify-content: space-between;
    margin-top: pxToRem(50);
    flex-flow: row;
    max-width: pxToRem(1140 + 80);
    margin: 0 auto;
    position: static;
    padding: 0;

  }

  &-workshop__title {
    h1 {
      font-size: pxToRem(40);
      margin-top: pxToRem(49);
      margin-bottom: pxToRem(30);
    }
  }

  .news-events__location {
    margin-bottom: pxToRem(10);
  }

  .news-events__meta {
    padding-right: pxToRem(26);
  }

  .workshop-price {
    margin-left: pxToRem(20);
  }

  .share-bar {
    display: block;
  }
}


/* for workshops section on perform homepage */
.p-perform-resources {
  .p-perform-resources__container {
    .perform--content {
      .content--search {
        .documents__wrapper {
          .documents__list {
            margin: 0 -1%;

            .documents__item {
              width: 31%;
              display: inline-block;
              vertical-align: top;
              margin: 1rem 1%;
            }
          }
        }
      }
    }
  }
}