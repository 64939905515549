/* Module: S */
.home {
    .landing-articles {
        padding-bottom: pxToRem(40);

        &:before {
            top: pxToRem(-35);
            bottom: 0;
        }

        &:after {
            content: '';
            height: pxToRem(5);
            background-color: $black-three;
            position: absolute;
            width: pxToRem($container-width-desktop);
            left: 50%;
            margin-left: pxToRem(- $container-width-desktop / 2);
            top: pxToRem(-35);
        }
    }
}

.landing-articles {
    background-color: $white-two;

    .single-column & {
        padding-left: 0;
        padding-right: 0; 
    }

    .content & {
        padding-left: 0;
        padding-right: 0;
    }
}

.landing-articles__list {
    padding: pxToRem(15) 0 pxToRem(30);
    position: relative;
    width: pxToRem($container-width-desktop);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .content &  {
        width: 100%;
        padding: 0;
    }
}


.landing-articles__item {
    width: calc((100% / 3) - 20px);
    transition: .2s transform ease;

    .content &  {
        width: calc((100% / 3) - 10px);
    }
}

.landing-articles__item:hover {
    transform: scale(1.05);
}

.landing-articles__item:first-of-type {
    margin-top: 0;
}

.landing-articles__image-wrapper {
}

.landing-articles__category {
}

.landing-articles__image {
    width: 100%;
    height: pxToRem(270);
    object-fit: cover;
}

.landing-articles__meta {
    border-bottom-width: pxToRem(10);
}

.landing-articles__name {
    margin-top: 0;
}

.landing-articles__link {
    font-size: pxToRem(28);
}

.landing-articles__desc {
    font-size: pxToRem(16);
    min-height: pxToRem(80);
}

.landing-articles__more {
    display: block;
    font-size: pxToRem(13);
}

.landing-articles__more .icon-arrow-right {
    margin-left: pxToRem(5);
    transition: .2s ease transform;
}

.landing-articles__more:hover .icon-arrow-right {
    transform: translateX(10px);
}