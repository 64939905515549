/* Module: S */
main {
  position: relative;
}
main.main {
  padding-bottom: pxToRem(50);
}
.content-wrapper {
  margin-top: pxToRem(30);
  display: flex;
  flex-flow: column;
}

.content__top-bar {
  margin: 0 pxToRem(-20) pxToRem(20) pxToRem(-20);
  padding: pxToRem(20) pxToRem(20) pxToRem(15) pxToRem(20);
  border-bottom: pxToRem(2) solid $black-two;
}

.content-wrapper {
  position: relative;
  //padding-top: pxToRem(40); In reponse to SWWR-8
}

.content__top-bar .breadcrumbs {
  position: absolute;
  top: 0;
}

.content {
  padding-left: pxToRem(20);
  padding-right: pxToRem(20);

  &__body {
    background-color: $white;
    margin: 0 pxToRem(-5);
    padding: 0 pxToRem(20) pxToRem(20) pxToRem(20);
    ul:not([class]) {
      overflow: hidden;
    }
  }

  p {
    color: $greyish-brown;

    &.intro {
      font-size: pxToRem(20);
    }
  }
}

 // Blockquote
  blockquote {
    font-weight: 600;
    color: $black-two;
    position: relative;
    padding-left: pxToRem(80);
    font-size: pxToRem(18);
    line-height: 1.35;
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  blockquote::before,
  blockquote::after {
    content: "";
    display: block;
    width: pxToRem(26);
    height: pxToRem(34);
    background-image: url("{{file_dest}}/quote.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: pxToRem(10);
  }
  blockquote::before {
    left: 0;
  }
  blockquote::after {
    left: pxToRem(32);
  }

  .blockquote__quote {
    color: $black-two;
  }

  .blockquote__image {
    order: 3;
    width: calc(100% + 80px);
    position: relative;
    left: pxToRem(-80);
    max-width: calc(100% + 80px);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .blockquote__author {
    font-size: 0.66em;
    line-height: 1.6;
    margin: 0;
    font-weight: 600;
    position: relative;
    color: $black-two;
  }
  .blockquote__author::before {
    content: "";
    display: block;
    width: pxToRem(15);
    height: pxToRem(2);
    background-color: $black-four;
    position: absolute;
    left: pxToRem(-30);
    top: calc(50% - 1px);
  }
  .blockquote__creditentials {
    font-size: 0.66em;
    line-height: 1.6;
    margin: 0;
    font-weight: 500;
  }

  // Notifications

  .notification {
    font-size: pxToRem(18);
    line-height: 1.56;
    font-weight: 600;
    color: $black-two;
    clear: both;
    overflow: auto;
    padding-left: pxToRem(65);

    & svg + h1,
    & svg + h2,
    & svg + h3,
    & svg + h4,
    & svg + h5,
    & svg + ul,
    & svg + p {
      margin-top: 0;
    }
  }

  .notification .svg-icon {
    float: left;
    display: block;
    width: pxToRem(50);
    height: pxToRem(50);
    border-bottom: pxToRem(2) solid $black-four;
    padding-left: pxToRem(11);
    padding-right: pxToRem(11);
    margin-right: 0;
    margin-left: pxToRem(-65);
    fill: $black;
  }

  .notification-error .svg-icon {
    fill: $white;
    background-color: $tomato-two;
  }

  .notification-warning .svg-icon {
    background-color: $sunflower-yellow;
  }

  .notification-ok .svg-icon {
    background-color: $medium-green;
  }

  table {
    width: 100%;
    text-align: left;
  }

  th {
    border-bottom: pxToRem(2) solid $black;
    padding-left: pxToRem(15);
    padding-top: pxToRem(15);
    padding-bottom: pxToRem(15);
    font-size: pxToRem(14);
  }

  td {
    border-bottom: pxToRem(2) solid $black;
    border-right: pxToRem(1) solid $pinkish-grey;
    padding-left: pxToRem(15);
    padding-top: pxToRem(15);
    padding-bottom: pxToRem(15);
    font-size: pxToRem(16);
    line-height: 1.38;
  }

  td:last-of-type {
    border-right: none;
  }

  ul,
  ol {
    li:not(.breadcrumbs__item):not(.share-bar__item):not(.documents__item):not(.search__item):not(.news-events__item):not(.landing-listing-simple__item):not(.risk-predictor__container li) {
      color: $greyish-brown;
      padding-left: 1rem;
    }
  }

// Images & Video

.content__image {
  margin-bottom: pxToRem(30);
  display: table;
}

.content__image--full {
  width: 100%;
}

.content__image figcaption,
.content__video figcaption {
  display: table-caption;
  caption-side: bottom;
  font-size: pxToRem(12);
  line-height: 1.67;
  color: $greyish-brown;
}

p.content__image img  {
    height: auto;
}

.content__video {
  width: 100%;
  margin-bottom: pxToRem(30);
  display: table;

  &--left,
  &--right,
  &--centre {
    figcaption {
      display: block;
    }
  }

  &-col-content {
    margin-top: pxToRem(15);
  }
}

// Documents section

.documents__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.documents__item {
  border-bottom: pxToRem(2) solid $black;
  padding: pxToRem(20) $outer-margin-mobile;
}

.documents__icon-wrapper .svg-icon {
  float: left;
  display: block;
  width: pxToRem(50);
  height: pxToRem(50);
  border-bottom: pxToRem(2) solid $black-four;
  padding-left: pxToRem(11);
  padding-right: pxToRem(11);
  margin-right: pxToRem(23);
  fill: $white;
  background: $white-six;
}

.documents__item--ms_word_document .documents__icon-wrapper .svg-icon {
  background-color: $cerulean;
}

.documents__item--ms_powerpoint_document .documents__icon-wrapper .svg-icon {
  background-color: $tomato-two;
}

.documents__item--ms_excel_document .documents__icon-wrapper .svg-icon {
  background-color: $medium-green;
}

.documents__item--pdf_file .documents__icon-wrapper .svg-icon {
  background-color: $tomato-two;
}

.documents__item--link .documents__icon-wrapper .svg-icon,
.documents__item--standard_page .documents__icon-wrapper .svg-icon,
.documents__item--text_file .documents__icon-wrapper .svg-icon {
  background-color: $white-six;
  fill: $cerulean;
  border-bottom-color: $cerulean;
}

.documents__meta {
}

.documents__name-link {
  color: $black-two;
  text-decoration: none;
  line-height: 1.29;
}

.documents__name-text {
  font-weight: normal;
  margin-bottom: pxToRem(5);
  margin-top: 0;
}

.documents__tag-list {
  font-size: pxToRem(12);
}

.documents__tag-list-label {
  display: inline;
  color: $warm-grey-four;
}

.documents__tag-list-tag {
  display: inline;
  color: $black-four;
}

.documents__tag-list strong.documents__tag-list-tag {
  font-size: pxToRem(12);
}

.documents__description {
  margin-top: pxToRem(32);
  font-size: pxToRem(14);
  color: $greyish-brown;
  line-height: 1.5;

  .documents__tag-list + & {
    margin-top: pxToRem(16);
  }
}

.documents__download-link {
  text-decoration: none;
}

.documents__download-link .icon-arrow-right {
  width: pxToRem(15);
  height: pxToRem(10);
}

// Information box
.info-box {
  border: pxToRem(1) solid $pinkish-grey;
  font-size: 16;
  line-height: 1.5;
  color: $black;
  padding: pxToRem(18) pxToRem(36) pxToRem(40) pxToRem(0);
  position: relative;
}

.info-box .icon-information {
  background-color: $sunflower-yellow;
  width: pxToRem(25);
  height: pxToRem(25);
  border-radius: pxToRem(25);
  padding: pxToRem(5);
  position: absolute;
  top: pxToRem(18);
  left: pxToRem(15);
}

.info-box__text {
  padding-left: pxToRem(59);
  margin: 0;
}
