/* Module: S */
.breadcrumbs {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: pxToRem(12);
    display: none;

    &__wrapper {
        display: none;
    }
}

.breadcrumbs--no-wrapper {
    padding: 0 pxToRem(20);
}

.breadcrumbs--consultations {
    height: pxToRem(40);
    line-height: pxToRem(40);
    padding-left: pxToRem(20);
}

.landing-container .breadcrumbs {
    padding: pxToRem(20) 0;
}

.breadcrumbs__item {
    display: inline-block;
}

.breadcrumbs__link {
    text-decoration: none;
    color: $warm-grey-three;
}

.breadcrumbs__item--active {
    font-weight: 600;
}

.breadcrumbs__item::after {
    content: '/';
    display: inline-block;
    padding-left: pxToRem(8);
    padding-right: pxToRem(6);
}

.breadcrumbs__item:last-of-type::after {
    display: none;
}