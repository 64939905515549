/* Module: S */
.curr-cons {
    position: relative;
    padding-bottom: pxToRem(50);
    margin: 0;
}

.curr-cons__icon {
    display: block;
    width: pxToRem(97);
    height: pxToRem(97);
    background-color: $marigold;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
}

.curr-cons__icon .icon-injury-icon,
.curr-cons__icon .icon-wall-icon {
    width: pxToRem(50);
    height: pxToRem(65);
}

.curr-cons__icon .icon-car-icon {
    width: pxToRem(64);
    height: pxToRem(64);
}

.curr-cons__heading {
}

.curr-cons__sub-heading {
}

.curr-cons__info {
    padding: 0 5px;
}

.curr-cons__status-box {
}

.curr-cons__days-remaining {
}

.progress-bar {
}

.progress-bar__progress {
}

.curr-cons__meta {
    display: flex;
    justify-content: space-between;
}

.curr-cons__status {
}