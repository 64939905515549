/* Module: S */
.home {
    .landing-articles {
        position: relative;

        &:before {
            content: '';
            background-image: url('{{file_dest}}/landing-art-bg-temp.png');
            background-position: center center;
            background-repeat: repeat;
            position: absolute;
            top: pxToRem(40);
            left: 0;
            right: 0;
            bottom: pxToRem(60);
        }
    }
}

.landing-articles {
    padding-left: pxToRem(15);
    padding-right: pxToRem(15);
    padding-bottom: pxToRem(10);
    margin-top: pxToRem(50);

    .content & {    
        padding-left: 0;
        padding-right: 0;
    }
}

.landing-articles__list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .content & {
        width: 100%;
    }
}

.landing-articles__item {
    margin-bottom: pxToRem(10);
    position: relative;
    z-index: 1;
}

.landing-articles__item-link {
    text-decoration: none;
}

.landing-articles__item:first-of-type {
    margin-top: pxToRem(-30);
}

.landing-articles__image-wrapper {
    position: relative;
}

.landing-articles__category {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $marigold;
    text-transform: uppercase;
    padding: pxToRem(5) pxToRem(10);
    letter-spacing: pxToRem(0.2);
    font-size: pxToRem(13);
    color: $black-two;
    font-weight: 600;
}

.landing-articles__image {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: pxToRem(217);
    object-fit: cover;
}

.landing-articles__meta {
    background-color: $white;
    padding: pxToRem(26) pxToRem(22);
    border-bottom: pxToRem(4) solid $black-three;
}

.landing-articles__name {
    display: inline-block;
    font-size: pxToRem(24);
    line-height: 1.21;
    font-weight: 500;
    position: relative;
    border-bottom: pxToRem(2) solid $marigold;
}

.landing-articles__link {
    color: $black-two;
    text-decoration: none;
}

.landing-articles__desc {
    font-size: pxToRem(15);
    line-height: 1.27;
    color: $greyish-brown;
}

.landing-articles__more {
    line-height: 1.71;
    color: $black-two;
    font-size: pxToRem(14);
    text-decoration: none;
    display: flex;
    justify-content: space-between;
}

.landing-articles__more .icon-arrow-right {
    width: pxToRem(19);
    height: pxToRem(12);
    margin-top: pxToRem(6);
}