/* Module: S */
.container {
    width: $container-md;
}

.container-fluid {
    padding-left: $outer-margin;
    padding-right: $outer-margin;
}

.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-offset-0,
.col-md-offset-1,
.col-md-offset-2,
.col-md-offset-3,
.col-md-offset-4,
.col-md-offset-5,
.col-md-offset-6,
.col-md-offset-7,
.col-md-offset-8,
.col-md-offset-9,
.col-md-offset-10,
.col-md-offset-11,
.col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-left: $half-gutter-width;
    padding-right: $half-gutter-width;
}

.col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-md-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
}

.col-md-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
}

.col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
}

.col-md-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
}

.col-md-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
}

.col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
}

.col-md-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
}

.col-md-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
}

.col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
}

.col-md-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
}

.col-md-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
}

.col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
}

.col-md-offset-0 {
    margin-left: 0;
}

.col-md-offset-1 {
    margin-left: 8.33333333%;
}

.col-md-offset-2 {
    margin-left: 16.66666667%;
}

.col-md-offset-3 {
    margin-left: 25%;
}

.col-md-offset-4 {
    margin-left: 33.33333333%;
}

.col-md-offset-5 {
    margin-left: 41.66666667%;
}

.col-md-offset-6 {
    margin-left: 50%;
}

.col-md-offset-7 {
    margin-left: 58.33333333%;
}

.col-md-offset-8 {
    margin-left: 66.66666667%;
}

.col-md-offset-9 {
    margin-left: 75%;
}

.col-md-offset-10 {
    margin-left: 83.33333333%;
}

.col-md-offset-11 {
    margin-left: 91.66666667%;
}

.start-md {
    justify-content: flex-start;
    text-align: start;
}

.center-md {
    justify-content: center;
    text-align: center;
}

.end-md {
    justify-content: flex-end;
    text-align: end;
}

.top-md {
    align-items: flex-start;
}

.middle-md {
    align-items: center;
}

.bottom-md {
    align-items: flex-end;
}

.around-md {
    justify-content: space-around;
}

.between-md {
    justify-content: space-between;
}

.first-md {
    order: -1;
}

.last-md {
    order: 1;
}