/* Module: S */
.tabs {
}

.tabs__item {
}

.tabs__item-link {
}

.tabs__content {
}