/* Module: S */
.content-aside { 
    @include flexbox();
    @include flex-flow(column);
}

.contact-box {
    background-color: $marigold;
    padding: pxToRem(20) pxToRem(20) pxToRem(30);
    border-bottom: pxToRem(4) solid $black-two;
    // align-self: flex-start;
    order: 2;
    margin: 0 pxToRem(15) pxToRem(50) pxToRem(15);
    width: auto;

    .content-aside & {
        padding-bottom: 0;
    }
}

.contact-box h1 {
    margin-top: 0;
    font-size: pxToRem(28);
}

.contact-box h2 {
    margin-top: pxToRem(15);
    margin-bottom: pxToRem(0);
    font-size: pxToRem(18);
}

.contact-box p {
    margin: 0;
    line-height: 1.75;
}

.contact-box__phone,
.contact-box__email {
    text-decoration: none;
    color: $black-two;
    text-decoration: underline;
    word-break: break-word;
}