/* Module: S */
.landing-promo {
    background-color: $marigold;
    padding: pxToRem($padding-global);
    border-bottom: pxToRem(5) solid $black;
    border-top: pxToRem(50) solid $white;
}

.landing-promo__image {
    margin-top: pxToRem(-50);
}

.landing-promo__title {
    position: relative;
    display: inline-block;
    margin-bottom: pxToRem(10);
    padding-bottom: pxToRem(8);
    font-size: pxToRem(28);
    font-weight: 500;
    padding-right: pxToRem(16);
    text-decoration: none;
    color: $black;
}

.landing-promo__title::after {
    content: '';
    display: block;
    width: 100%;
    height: pxToRem(2);
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $black-two;
}

.landing-promo__desc {
    font-size: pxToRem(16);
    line-height: 1.5;
    color: $black-two;
}

.landing-promo__more {
    font-size: pxToRem(13);
    color: $black-two;
    text-decoration: none;
}

.landing-promo__more .icon-arrow-right {
    width: pxToRem(17);
    height: pxToRem(11);
    margin-left: pxToRem(8);
}