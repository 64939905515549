/* Module: S */
.share-bar--footer {
    order: 4;
    width: 100%;
}

.share-bar--long-page {
    text-align: right;
}

.share-bar__header {
    font-weight: 500;
    font-size: pxToRem(14);
    color: $greyish-brown;
    display: inline-block;
    padding-right: pxToRem(14);
    margin-top: 0;
    margin-bottom: 0;
}

.share-bar__list {
    display: inline-block;
}

.share-bar__link {
    padding-left: pxToRem(6);
    padding-right: pxToRem(6);
    position: relative;

    &:first-of-type {
        padding-left: pxToRem(6);
    }

    &:hover {
        &:after {
            content: '';
            display: block;
            height: pxToRem(2);
            background-color: $marigold;
            left: pxToRem(6);
            right: pxToRem(6);
            bottom: 0;
            position: absolute;
        }
    }
}

.icon-social-facebook {
    width: pxToRem(7);
    height: pxToRem(13);
}

.icon-social-instagram {
    width: pxToRem(11);
    height: pxToRem(11);
}

.icon-social-twitter {
    width: pxToRem(13);
    height: pxToRem(11);
}

.icon-social-youtube {
    width: pxToRem(15);
    height: pxToRem(11);
}

.icon-social-linkedin {
    width: pxToRem(14);
    height: pxToRem(13);
}