/* Module: S */
.header-nav {
    width: 100%;
    order: 1;
}

.header-nav__list {
    margin: 0;
    padding: pxToRem(15) pxToRem(7);
    list-style-type: none;
    font-size: pxToRem(16);
    line-height: 2.25;
}

.header-nav__link {
    text-decoration: none;
    color: $black-two;

    br {
    	display: none;
    }
}