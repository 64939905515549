/* Module: S */
.how-it-works {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    width: pxToRem(1140);
}

.hit__heading {
    font-size: pxToRem(50);
    margin-top: pxToRem(10);
}

.hit__list {
    display: flex;
    justify-content: space-between;
}

.hit__item {
    width: calc((100% / 3) - (60px / 3));
}
