$font-proximaNova: 'Proxima Nova';

@font-face {
    font-family: $font-proximaNova;
    src: url('{{file_dest}}/ProximaNova-Regular.woff2') format('woff2'),
         url('{{file_dest}}/ProximaNova-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: $font-proximaNova;
    src: url('{{file_dest}}/ProximaNova-Semibold.woff2') format('woff2'),
         url('{{file_dest}}/ProximaNova-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}