/* Module: S */
.blog {
    padding-bottom: pxToRem(20);
    width: 100%;
}

.blog__list {
    display: flex;
    flex-wrap: wrap;
}

.blog__item {
	width: 50%;
}

.blog__wrapper {
	height: 100%;
	padding-bottom: pxToRem(38);
}

.blog__meta {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}