/* Module: S */
// Global form elements
input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: pxToRem(1) solid $matrix-form__border-colour;
  border-radius: 0;
  padding: pxToRem(8) pxToRem(13);
  width: 100%;
  @extend %border-box;

  &:focus {
    border-bottom-color: $matrix-form__border-colour--focus;
    border-bottom-width: pxToRem(2);
    padding-bottom: pxToRem(7);
    outline: none;
  }
}

input[type="file"] {
  width: 100%;
}

textarea {
  border: pxToRem(1) solid $matrix-form__border-colour;
}

select {
  background: #fff url("../mysource_files/arrow-bottom.svg") calc(100% - 0.625rem) center no-repeat;
  padding-right: pxToRem(25);

  &::-ms-expand {
    // for IE 11
    display: none;
  }
}

fieldset {
  border: 0;
  margin: 0;
  min-width: initial;
  padding: 0;
}

// Input radio and checkbox
input[type="radio"],
input[type="checkbox"] {
  @extend %screen-hide;
}

input[type="radio"]+label:not([class]),
input[type="checkbox"]+label:not([class]) {
  color: $black-two;
  display: inline-block;
  font-weight: normal;
  margin: 0;
  padding-left: 1.875rem;
  position: relative;

  &::before {
    background-color: $white;
    border: pxToRem(2) solid $black-two;
    content: "";
    height: pxToRem(19);
    left: 0;
    position: absolute;
    top: pxToRem(1);
    width: pxToRem(19);
  }

  &:hover {
    cursor: pointer;

    &::before {
      background-color: $pinkish-grey-two;
    }
  }
}

input[type="radio"]:focus+label:not([class]),
input[type="checkbox"]:focus+label:not([class]) {
  text-decoration: underline;

  &::before {
    background-color: $white;
  }
}

input[type="radio"]:disabled+label:not([class]),
input[type="checkbox"]:disabled+label:not([class]) {
  &::before {
    background-color: $white;
    border-style: dotted;
  }
}

input[type="radio"]+label:not([class]) {
  &::before {
    @include sq-border-radius(50%);
  }
}

input[type="radio"]:checked+label:not([class]) {
  &::after {
    background-color: $black-two;
    content: "";
    height: pxToRem(9);
    left: pxToRem(5);
    position: absolute;
    top: pxToRem(6);
    width: pxToRem(9);
    @include sq-border-radius(50%);
  }
}

input[type="radio"]:disabled:checked+label:not([class]) {
  &::after {
    background-color: $black-two;
  }
}

input[type="checkbox"]:checked+label:not([class]) {
  &::after {
    border: solid $black-two;
    border-width: 0 pxToRem(3) pxToRem(3) 0;
    content: "";
    height: pxToRem(13);
    left: pxToRem(6);
    position: absolute;
    top: pxToRem(3);
    width: pxToRem(8);
    @include sq-transform(rotate(45deg));
  }
}

input[type="checkbox"]:disabled:checked+label:not([class]) {
  &::after {
    border-color: $black-two;
  }
}

// Single field wrapper
.sq-form-question {
  padding: 0.5em 0;
  @extend %clearfix;
}

// Titles & Labels
.sq-form-question-title,
.sq-form-question-answer label {
  color: $black-two;
  display: inline-block;
  font-size: pxToRem(14);
  font-weight: 600;
  margin: 0.5em 0;
}

// Lists
.sq-form-question-tickbox-list ul,
.sq-form-question-option-list ul {
  @extend %list-reset;
}

.sq-form-question-tickbox-list li,
.sq-form-question-option-list li {
  clear: left;
  display: block;
  padding: 0.5em 0;
}

.sq-form-question-answer li {
  line-height: inherit;
}

// Notes
.sq-form-question-note {
  color: $black-two;
  display: block;
  font-size: pxToRem(12);
  font-style: normal;
  font-weight: normal;
  margin-bottom: 0.5em;
}

// Date/Time
.sq-form-question-datetime input[type="password"],
.sq-form-question-datetime input[type="text"],
.sq-form-question-datetime label,
.sq-form-question-datetime select {
  @extend %inline-block;
  margin-bottom: 0.5em;
  margin-right: 0;
  width: calc(50% - 1em);
}

.sq-form-question-datetime label,
.sq-form-question-datetime input {
  font-weight: normal;
  margin-right: 0.5em;
}

// Errors
abbr.sq-form-required-field {
  border: none;
}

div.sq-form-error,
p.sq-form-error {
  clear: left;
  color: $matrix-form__error-text;
  font-weight: 500;
  margin: pxToRem(12) 0;
}

.sq-form-question-error input,
.sq-form-question-error textarea,
textarea:invalid,
input:invalid {
  border-color: $matrix-form__error-text;
  color: $matrix-form__error-text;
}

.sq-form-question-error input:focus,
input:invalid:focus {
  border-color: $matrix-form__border-colour--focus;
  color: $black;
}

// Tickbox table
.sq-backend-table {
  margin-bottom: 1em;
}

.sq-backend-table-cell,
.sq-backend-table-header {
  color: $black-two;
  font-size: pxToRem(14);
  font-weight: 600;
  padding: pxToRem(8);
}

.content form {
  margin-bottom: pxToRem(50);
}


/* for fireworks display form */
.webservice_form_fd {
  .sq-form-question-fd1 {
    clear: both;
  }

  .sq-form-question-fd2,
  .sq-form-question-fd3 {
    width: 100%;
    display: block;
    margin: 1em 0;
    .sq-form-question-answer input {
      background: url(../mysource_files/datepicker.svg) right center no-repeat;
    }
  }
  .sq-form-control {
    margin-top: 2.5em;
  }
}