/* Module: S */
.home-links {
  background-color: $marigold;
  text-align: center;
}

.home-links__list {
  font-size: pxToRem(20);
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: pxToRem(1140);
  margin: 0 auto;
  display: block;
}

.home-links__item {
  display: inline-block;
  width: pxToRem(300);
  padding-left: pxToRem(24);
  text-align: left;
  margin-bottom: 0;
  padding-right: 0;
}

.home-links__item::after {
  height: pxToRem(86);
  left: pxToRem(-19);
  top: pxToRem(13);
  right: auto;
}

.home-links__link {
}

.home-links__item .icon-arrow-right {
  width: pxToRem(20);
  height: pxToRem(13);
  display: block;
  //   left: pxToRem(24);
  top: auto;
  margin-top: pxToRem(10);
  display: block;
  right: auto;
  transition: 0.1s transform ease;
  position: relative;
}

.home-links__item:hover .icon-arrow-right {
  transform: translateX(20px);
}

.home-links__text {
  display: block;
  padding-top: pxToRem(25);
}
