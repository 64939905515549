/* Module: S */
.perform {
  &--content {
    background-image: url('{{file_dest}}/landing-art-bg-temp.png');
    background-position: center center;
    background-repeat: repeat;
    padding: pxToRem(15) 0;
  }

  &-workshop__title {
    padding: 0 pxToRem(20);

    h1 {
      font-weight: normal;
      margin-top: 0;
    }
  }

  &-workshop__content {
    padding: 0 pxToRem(20) pxToRem(20) pxToRem(20);
  }

  .workshop-price {
    margin: 0;
    font-size: pxToRem(14);

    &:hover {
      text-decoration: none !important;
    }
  }

  .share-bar {
    display: none;
  }

  .documents__wrapper {
    width: 100%;
  }
}

/* for workshops section on perform homepage */
.p-perform-workshops {
  padding-bottom: 2.5rem;

  .p-perform-workshops__container {
    text-align: center;
    max-width: 71.25rem;
    margin: 0 auto;

    .p-perform-workshops__title {
      font-size: 3.125rem;
      font-weight: normal;
    }

    .p-perform-workshops__summary {
      font-size: 1.125rem;
    }

    a.btn {
      margin: 1.6875rem auto;
    }

    .section__wrapper {
      background: #fff;
      max-width: 100%;
      padding: 1.5rem 0;

      h2.home-events__title {
        font-size: 1.25rem;
        color: #181818;
      }

      p.home-events__desc {
        font-size: 1rem;
        color: #494949;
      }
    }
  }
}

.p-perform-resources {
  background-image: url("../mysource_files/landing-art-bg-temp.png");
  background-position: center center;
  background-repeat: repeat;
  padding: 0.9375rem 0 3rem;

  .p-perform-resources__container {
    text-align: center;
    max-width: 71.25rem;
    margin: 0 auto;

    .p-perform-resources__title {
      font-size: 3.125rem;
      font-weight: normal;
    }

    .p-perform-resources__summary {
      font-size: 1.125rem;
    }

    .perform--content {
      background: none;

      .content--search {
        background: none;

        .documents__wrapper {
          width: 100%;

          .documents__list {
            margin: 0;

            .documents__item {
              width: 100%;
              display: block;
              margin: 1rem 0;
              background: #fff;
              min-height: 6rem;

              .documents__meta {
                .documents__name-link {
                  text-align: left;
                }

                .documents__download-link {
                  display: none;
                }

                .documents__description {
                  display: none;
                }
              }

            }
          }
        }
      }
    }

    a.btn {
      margin: 1.6875rem auto;
    }
  }
}

.p-perform-case-studies {
  padding: 2.5rem 0;
  background-image: url("../mysource_files/landing-art-bg-temp.png");
  background-position: center center;
  background-repeat: repeat;

  .p-perform-case-studies__container {
    text-align: center;
    max-width: 71.25rem;
    margin: 0 auto;

    .p-perform-case-studies__title {
      font-size: 1.125rem;
      line-height: 1.33;
      font-weight: 600;
      margin-bottom: 1.875rem;
      text-transform: uppercase;

      span {
        display: block;
        font-size: 3.125rem;
        font-weight: normal;
        text-transform: capitalize;
      }
    }

    .p-perform-case-studies__summary {
      font-size: 1.125rem;
    }

    .section {
      &:before {
        background: none;
      }

      .section__wrapper {
        max-width: 100%;
        padding: 1.5rem 0;
        .landing-articles__meta{
          text-align:left;
          padding-top:1rem;
          .landing-articles__name{
            margin-bottom:1rem;
          }
        }
      }
    }


    a.btn {
      margin: 1.6875rem auto;
    }
  }
}