/* Module: S */
.header-nav {
    width: calc(100% - 230px);
    position: absolute;
    bottom: 0;
    left: pxToRem(40);
}

.header-nav__list {
    display: flex;
    justify-content: space-between;
    line-height: 1.33;
    font-size: pxToRem(15);
    padding-left: 0;
    padding-right: 0;
}

.header-nav__item {
}

.header-nav__link {
    position: relative;
    display: inline-block;

    br {
        display: inline;
    }
}

.header-nav__item--active .header-nav__link,
.header-nav__link:hover {
    &::after {
        position: absolute;
        bottom: pxToRem(-16);
        left: pxToRem(-5);
        right: pxToRem(-5);
        content: '';
        display: block;
        height: pxToRem(4);
        background-color: $marigold;
    }
}