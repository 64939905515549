/* Module: S */
.hazards {

  .hazards__list {
    
    margin-bottom: pxToRem(50);

    &-anchor {
      @include flexbox();
      @include justify-content(center);
      @include flex-flow(column);
      width: pxToRem(51);
      height: pxToRem(44);
      background: $marigold;
      text-align: center;
      border-bottom: 2px solid $black;
      margin-bottom: pxToRem(20);
      h3 {
        font-size: pxToRem(30);
        margin-top: pxToRem(0);
        margin-bottom: pxToRem(0);
      }
    }

    .news-events__more {
      left: pxToRem(-8);
    }
    
    .news-events__link {
      font-size: pxToRem(28);
      font-weight: 400;
    }

    .news-events__list { 
      margin-left: 0;
      margin-right: 0;
    }

    .news-events__item {
      border-bottom-width: pxToRem(5);
      padding: pxToRem(20);
      margin-bottom: 0;

    }

    .news-events__desc {
      display: block;
      line-height: 1.5;
    }
    
     .icon-arrow-right {
      top:0;
      bottom: pxToRem(-10);
     }
  }
}