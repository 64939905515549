/* Module: S */
.home-events {
    color: $black-two;
    text-align: center;
    background-color: $white;    

    &__wrapper {
        padding-top: pxToRem(10);  
    }     
}

.home-events__header {
    font-size: pxToRem(36);
    text-align: center;
    font-weight: 500;
}

.home-events__list {
    margin: 0;
    padding: 0 pxToRem($padding-global);
    list-style-type: none;
    text-align: left;
}

.home-events__item {
    padding-left: pxToRem(49) !important;
    position: relative;
    border-bottom: pxToRem(3) solid $black;
    padding-top: pxToRem(21);
    padding-bottom: pxToRem(21);
}

.home-events__item .icon-arrow-right {
    display: none;
}

.home-events__date {
    position: absolute;
    top: pxToRem(21);
    left: 0;
}

.home-events__day {
    font-size: pxToRem(26);
    line-height: 1.15;
    font-weight: 600;
}

.home-events__month {
    font-size: pxToRem(14);
    text-transform: uppercase;
    display: block;

}

.home-events__title {
    font-size: pxToRem(18);
    font-weight: 600;
    margin: 0;
}

.home-events__link {
    text-decoration: none;
    color: $black-two;
}

.home-events__desc {
    line-height: 1.27;
    font-size: pxToRem(15);
    color: $greyish-brown;
    margin: 0;
}

.home-events__location {
    font-size: pxToRem(13);
    color: $warm-grey-three;
    padding-top: pxToRem(10);
    display: block;
}

.home-events__location-label {
    font-weight: 600;
}

.home-events__location .icon-location {
    width: pxToRem(11);
    height: pxToRem(15);
    position: relative;
    top: pxToRem(3);
    margin-right: pxToRem(3);
}

.home-events__more-button {
    display: inline-block;
    margin: pxToRem(27) auto;
    text-decoration: none;
    color: $black-four;
}