/* Module: S */
// Date/Time
.sq-form-question-datetime input[type='password'],
.sq-form-question-datetime input[type='text'],
.sq-form-question-datetime label,
.sq-form-question-datetime select {
    margin-bottom: initial;
    width: auto;
}

/* for fireworks display form */
.webservice_form_fd {
    .sq-form-question-fd2,
    .sq-form-question-fd3 {
        width: 45%;
        display: inline-block;
        margin: 1em 4% 1em 0;
    }
}