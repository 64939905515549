/* Module: S */
.section {
	&-disclaimer {
		.section {
			&__wrapper {
				margin: 0 auto;
				width: pxToRem(1140);
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
}