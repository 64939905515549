/* Module: S */
.landing-more {
  text-align: center;
  padding: pxToRem(40) pxToRem(20) pxToRem(60);
  max-width: pxToRem(750);
  margin-left: auto;
  margin-right: auto;
  .perform & {
    text-align: inherit;
  }
}

.landing-more__heading {
  color: $black;
  text-transform: uppercase;
  font-size: pxToRem(18);
  font-weight: 500;
  line-height: 1.2;
}

.landing-more__heading strong {
  display: block;
  text-transform: initial;
  font-size: pxToRem(50);
  font-weight: 500;
  .perform & {
    font-size: pxToRem(36);
  }
}

.landing-more__text {
  color: $greyish-brown;
  font-size: pxToRem(18);
  line-height: 1.56;
}

.landing-more .btn {
  margin-top: pxToRem(20);
  display: inline-block;
}
