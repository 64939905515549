/* Module: S */
.home-today-safeworks {
    padding: pxToRem(56) 0 pxToRem(20);
    border-bottom: pxToRem(5) solid $black-three;
    width: pxToRem(1140);
    margin-left: auto;
    margin-right: auto;
}

.home-ts__header {
    font-size: pxToRem(18);
    line-height: 1.33;
    font-weight: 600;
    margin-bottom: pxToRem(30);
}

.home-ts__header-name {
    font-size: pxToRem(50);
}

.home-ts__links-bar {
    text-align: center;
}

.home-ts__link {
    background-color: transparent;
    height: auto;
    line-height: normal;
    padding-left: pxToRem(31);
    padding-right: pxToRem(52);
    display: inline-block;
    font-size: pxToRem(15);
    border-right: pxToRem(1) solid $warm-grey-seven;
}

.home-ts__link:hover::after {
    content: '';
    display: block;
    width: calc(100% + 23px);
    height: pxToRem(2);
    background-color: $marigold;
    position: relative;
    top: pxToRem(2);
}

.home-ts__link .icon-arrow-right {
    width: pxToRem(12);
    height: pxToRem(8);
    right: pxToRem(30);
    top: calc(50% - 4px);
}

.home-ts__sharebox {
    display: inline-block;
    margin-left: pxToRem(28);
}

.home-ts__sharebox .share-bar__header {
    font-size: pxToRem(15);
    font-weight: 600;
}