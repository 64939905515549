/* Module: S */
.tabs {
}

.tabs__tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: pxToRem(10);
}

.tabs__item {
  border-bottom: 2px solid $warm-grey-three;
  line-height: pxToRem(47);
  margin-right: pxToRem(10);
  width: 100%;
}

.tabs__item:last-of-type {
  margin-right: 0;
}

.tabs__item--active {
  border-bottom-color: $marigold;
}

.tabs__item-link {
  text-decoration: none;
  font-size: pxToRem(16);
  font-weight: 500;
  color: $black;
  display: inline-block;
  line-height: 22px;
}

.tabs__item--active .tabs__item-link {
  font-weight: 600;
}

.tabs__content {
  display: none;
}

.tabs__content--active {
  display: block;
}
