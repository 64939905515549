/* Module: S */
.resources-topic {
  margin-bottom: pxToRem(30);
  &__list {
    @include flexbox();
    @include justify-content(space-between);
  }

  &__header {
    margin-top: pxToRem(70);
  }

  &__item {
    width:calc((100% / 3) - 20px;
    margin-bottom: 0;
  }

  &__link {
  }
}