/* Module: S */
.p-perform-infograph {

   justify-content: space-between;
   flex-flow: row;
   // max-width: pxToRem(1140 + 80);
   margin: 0 auto;
   position: static;
   padding: 0;
   margin-top: pxToRem(50);
   
   &__container {
    max-width: pxToRem(1140 + 80);
    margin: 0 auto;
   }

   &__items {
    @include flex-direction(row);
    @include justify-content(space-between);
    padding: 0 pxToRem(20);
   }
  
   &__item {
    @include flexbox();
    width: 100%;
    margin-right: pxToRem(20);

    &:last-child {
      margin-right: 0;
      width: 101%;
    }
   }
   
   &__tile {
    display: block;
    text-align: inherit;
    border-bottom: 2px black solid;
    padding: pxToRem(28) pxToRem(29) pxToRem(45) pxToRem(28);
    transition: all .2s ease-in-out; 
    &:hover {
      transform:scale(1.05);
    }
   }
   
   &__img {
    width: auto;
    display: inline-block;
   }

   &__img img {
    height: pxToRem(74);
    width: auto;
   }

   &__title {
    font-size: pxToRem(50);
    padding: pxToRem(15) 0;
   }
   
   &__content {
    max-width: pxToRem(220);
    display: inline-block;
    h1 {
      margin-top: 0;
      color: #181818;
    }

    h1,p {
      font-size:pxToRem(28);
      line-height: 1.14;
    }
   }

   &__summary {
    padding-bottom:pxToRem(5);
    text-align: center;
   }
}