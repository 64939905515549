/* Module: S */
.home-updates {
    &__wrapper {
        width: pxToRem(1140);
        margin-left: auto;
        margin-right: auto;
        padding-top: pxToRem(30);        
        padding-bottom: pxToRem(30);        
    }
}

.home-updates__header {
    font-size: pxToRem(18);
}

.home-updates__main-name {
    font-size: pxToRem(50);
}

.home-updates__list {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
}

.home-updates__item {
    width: calc(33% - 15px);
    transition: .1s transform ease-in;
}

.home-updates__item:hover {
    transform: scale(1.05);
}

.home-updates__item-link {
    min-height: pxToRem(300);
    padding-left: pxToRem(31);
    padding-right: pxToRem(31);
    padding-top: pxToRem(55);
}

.home-updates__item-link::after {
    height: pxToRem(10);
}

.home-updates__title {
    line-height: 1.29;
    font-size: pxToRem(28);
    border-bottom-width: pxToRem(2);
}

.home-updates__desc {
    font-size: pxToRem(16);
    line-height: 1.5;
    padding-top: pxToRem(10);
}

.home-updates__more {
    font-size: pxToRem(13);
    display: inline-block;
    bottom: pxToRem(28);
    left: pxToRem(31);
}

.home-updates__more .icon-arrow-right {
    margin-left: pxToRem(10);
    position: relative;
    top: pxToRem(2);
}