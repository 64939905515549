/* Module: S */
.detailed-guide {

  .page-links {
    @include flexbox();
    @include justify-content(space-between);
    padding: 0;

    &__item {
      list-style: none;
    }

    &__link {
      text-decoration: none;
      color: $black;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }

    &__text {
      font-weight: normal;
    }
  }

  .guide-links {

    &__item {
      padding: pxToRem(4) pxToRem(1);
      &.current {
        text-decoration: underline;
      }
    }

    &__link {
      text-decoration: none;
      color: $black;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}