/* Module: S */
.hero {
    color: $white;
    padding-top: pxToRem(70);
    padding-bottom: pxToRem(90);
    border-top: none;

    h1 {
        font-size: pxToRem(50);
    }

    h2 {
        font-size: pxToRem(20);
        max-width: pxToRem(830);
        margin: 0 auto;
    }

    &:before {
        background-color: transparent;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), $black);
    }

    .hazards & {
        padding-bottom: pxToRem(0);
    }
}


.hero__event-wrapper {
    width: pxToRem($container-width-desktop);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-left: pxToRem(112);
}

.hero__event-details {
    margin-bottom: 0;
}

.hero__event-details span {
    position: relative;
    padding-right: pxToRem(37);
    display: inline;
}

.hero__event-details span::after {
    content: '';
    display: block;
    width: pxToRem(1);
    height: pxToRem(34);
    background-color: $marigold;
    transform: rotate(25deg);
    position: absolute;
    right: pxToRem(14);
    top: pxToRem(-10);
}

.hero__event-date-box {
    background-color: $marigold;
    border-bottom: pxToRem(5) solid $black-two;
    width: pxToRem(68);
    height: pxToRem(70);
    text-align: center;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    line-height: 1;
    display: flex;
    position: absolute;
    left: 0;
    top: pxToRem(15);
    color: $black-two;
}

.hero__event-day-box {
    font-size: pxToRem(28);
    display: block;
}

.hero__event-month-box {
    font-size: pxToRem(14);
    display: block;
}

.hero__event-location .icon-location {
    fill: $white;
}

.hazards {
    &__container {
        background: none;
        width: 100%;
        min-width: 0;
        margin: 0 auto;
    }

    &__item-container{
        @include flexbox();
        @include justify-content(space-around);
        width: pxToRem(1140);
        margin: 0 auto;
        position: relative;
        margin-top: pxToRem(53);
        padding-bottom: pxToRem(20);
        margin-bottom: 0;
    }

    &__item {
        background:none; 
        margin-bottom: 0;
        // width: auto;
        // height: auto;
        .hazards__link {
            color: $white;
            &:hover {
                text-decoration: underline;
                color: $marigold;
            }
        }
    }

    .filters__heading {
        display: none;
    }
}
