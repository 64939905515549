/* Module: S */
/*
-----------------
9. Landing Pages
-----------------
*/
.landing .main-content>p:first-of-type {
  font-weight: 600;
}

.lt-ie9 .landing .main-content>p:first-of-type {
  font-weight: normal;
  font-family: "proxima-nova-n6", "proxima-nova", sans-serif;
}

@media only screen and (min-width: 60em) {
  .landing .main-content>p:first-of-type {
    width: 68%;
  }
}

.landing-spread {
  margin: 3em 0 2.5em;
  width: 100%;
  height: auto;
}

.landing-list-wrapper--full-width {
  margin: 2.5rem 0;
}

@media only screen and (min-width: 60em) {
  .landing-list-wrapper--full-width {
    margin: 3.75rem 0;
  }
}

.landing-list {
  overflow: hidden;
  padding: 0;
}

@media only screen and (min-width: 60em) {
  .main-content .landing-list {
    display: block;
    width: auto;
    margin: 3em 0;
  }

  .landing-list-wrapper--full-width .landing-list {
    margin: 2em -0.5rem 4em -0.4375rem;
  }
}

.main-content .landing-list__item {
  padding-left: 0;
}

.main-content .landing-list__item:before {
  content: none;
}



.landing-list--boxes .landing-list__new-item {
  background-color: $black;
  //border: 1px solid black;
  height: 11.3rem !important;
  padding: 0 0 !important;
  margin-bottom:5.5rem !important;
}

.landing-list--boxes .landing-list__item {
  background-color: $marigold;
  margin: 0 0 pxToRem(16);
  padding: 1.25rem;
  position: relative;
  text-align: center;

  .landing-list__new-h {
    font-size: pxToRem(16);
    margin-top: 0;
    top: inherit;
    height: pxToRem(64);
    bottom: pxToRem(-80);
    line-height: 1.2rem;
  }
}

@media only screen and (min-width: 960px) and (max-width: 1200px) {
  .landing-list--boxes .landing-list__item {
    margin-bottom: pxToRem(45);

    .landing-list__new-h {
      top: inherit;
      font-size: pxToRem(16);
    }
  }
}

@media only screen and (min-width: 60em) {
  .landing-list--boxes .landing-list__item {
    margin: 1.5625rem 0.9375rem 3.4375rem;
    padding: 0;
  }
}

@media only screen and (min-width: 60em) {
  .landing-list__item {
    display: block;
    width: 68%;
    padding: 0 0 0 55px;
  }

  .main-content .landing-list__item {
    padding-left: 55px;
  }

  .columned .landing-list__item {
    width: 33.3%;
    float: left;
  }

  .two-col .landing-list__item {
    width: 100%;
  }

  .landing-list--boxes .landing-list__item {
    height: 16rem;
    margin: 0 0.5rem 1rem;
    padding: 0 0.9375rem;
    text-align: center;
    width: calc(49% - 13px);
    background-color: $marigold !important;
    border: none;
  }

  /* for full-width pages without sidebar */
  main.no-sidebar {
    .landing-list--boxes .landing-list__item {
      width: calc(33% - 13px);
    }
  }

  .columned .landing-list__item:first-child+li+li+li,
  .columned .landing-list__item:first-child+li+li+li+li+li+li,
  .columned .landing-list__item:first-child+li+li+li+li+li+li+li+li+li {
    clear: left;
  }
}

.landing-list--boxes .landing-list__item__inner {
  -ms-flex-align: center;
  align-items: center;
  -webkit-display: flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
}

.landing-list--boxes .landing-list__item__details {
  color: $black;
  left: 0;
  opacity: 0;
  padding: 1.25rem;
  pointer-events: none;
  position: absolute;
  text-align: left;
  top: 3.125rem;
  transition: opacity 0.25s, top 0.5s;
  font-weight: 400;
}

.landing-list__item:hover .landing-list__item__details {
  opacity: 1;
  top: 0;
}

.landing-list--boxes .landing-list__icon {
  background-image: url("../mysource_files/Consultation.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  color: $black;
  display: block;
  height: 5.3125rem;
  margin: 0 auto;
  transition: opacity 0.25s;
  width: 6.875rem;
}

.landing-list__item--contact .landing-list__icon {
  background-image: url("../mysource_files/Contact.svg");
  height: 5.0625rem;
  width: 4rem;
}

.landing-list__item--duty-holder .landing-list__icon {
  background-image: url("../mysource_files/DutyHolder.svg");
  height: 5.25rem;
  width: 7rem;
}

.landing-list__item--events .landing-list__icon {
  background-image: url("../mysource_files/Events.svg");
  height: 4.375rem;
  width: 5.5625rem;
}

.landing-list__item--faqs .landing-list__icon {
  background-image: url("../mysource_files/FAQs.svg");
  height: 4.9375rem;
  width: 2.6875rem;
}

.landing-list__item--hsc .landing-list__icon {
  background-image: url("../mysource_files/HSC.svg");
  height: 4.9375rem;
  width: 5.625rem;
}

.landing-list__item--hsr .landing-list__icon {
  background-image: url("../mysource_files/HSR.svg");
  height: 5.3125rem;
  width: 7.625rem;
}

.landing-list__item--related-programs .landing-list__icon {
  background-image: url("../mysource_files/RelatedPrograms.svg");
  height: 5.3125rem;
  width: 5.5rem;
}

.landing-list__item--resources .landing-list__icon {
  background-image: url("../mysource_files/Resources.svg");
  height: 5.25rem;
  width: 5rem;
}

.landing-list__item--tools .landing-list__icon {
  background-image: url("../mysource_files/ToolsToHelp.svg");
  height: 4.625rem;
  width: 3.5rem;
}

.landing-list__item--what-can-i-do .landing-list__icon {
  background-image: url("../mysource_files/WhatCanIDo.svg");
  height: 5.8125rem;
  width: 4.8125rem;
}

.landing-list__item--work-groups .landing-list__icon {
  background-image: url("../mysource_files/WorkGroups.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--building-better-services .landing-list__icon {
  background-image: url("../mysource_files/ico-building-better-services.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--getting-involved .landing-list__icon {
  background-image: url("../mysource_files/ico-getting-involved.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--home .landing-list__icon {
  background-image: url("../mysource_files/ico-home.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--intro .landing-list__icon {
  background-image: url("../mysource_files/ico-intro.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--our-progress .landing-list__icon {
  background-image: url("../mysource_files/ico-our-progress.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--priorities .landing-list__icon {
  background-image: url("../mysource_files/ico-priorities.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--strat .landing-list__icon {
  background-image: url("../mysource_files/ico-strat.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--vision .landing-list__icon {
  background-image: url("../mysource_files/ico-vision.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--whsl .landing-list__icon {
  background-image: url("../mysource_files/ico-whsl.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--case-studies .landing-list__icon {
  background-image: url("../mysource_files/CaseStudies.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--entry-permits .landing-list__icon {
  background-image: url("../mysource_files/EntryPermits.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--provisinal-improvement .landing-list__icon {
  background-image: url("../mysource_files/ProvisionalImprovementNotices.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--resolving-issues .landing-list__icon {
  background-image: url("../mysource_files/ResolvingIssues.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item--safety-complaints .landing-list__icon {
  background-image: url("../mysource_files/SafetyComplaints.svg");
  height: 5.0625rem;
  width: 6.5rem;
}

.landing-list__item:hover .landing-list__icon {
  opacity: 0;
}

.landing-list__link,
.landing-list__link:visited {
  border-bottom: 0;
}

.landing-list__link:focus,
.landing-list__link:hover,
.landing-list__link:visited:focus,
.landing-list__link:visited:hover {
  border-bottom: 0;
}

@media only screen and (min-width: 60em) {

  .columned .landing-list__link,
  .columned .landing-list__link:visited {
    display: block;
    margin: 0;
  }

  .reduced-padding .landing-list__link,
  .reduced-padding .landing-list__link:visited {
    margin: 0;
  }

  .landing-list--boxes .landing-list__link,
  .landing-list--boxes .landing-list__link:visited {
    width: 100%;
  }
}

.landing-list__img {
  width: 100%;
  height: 175px;
  overflow: hidden;
  margin: 2em 0 1.5em;
}

.landing-list__img img {
  display: block;
  max-width: none;
  width: 100%;
}

.landing-list__item .landing-list__background-img {
  width: 100%;
  height: 182px;
  // background-color: black;
  overflow: hidden;
}

.landing-list__item .landing-list__background-img img {
  width: 100%;
  height: 182px;
  display: block;
  max-width: none;
  transition: opacity 0.50s, ;
}

.landing-list__item:hover .landing-list__background-img img {
  opacity: 0.05;
}

@media only screen and (max-width: 960px) {
  .landing-list__item .landing-list__background-img {
    width: 110%;
    margin-top: -20px;
  }

  .landing-list__item .landing-list__background-img img {
    height: auto;
    margin-top: -20%;
  }

  /* 2 panels for tablet view */
  .landing-list--boxes .landing-list__item {
    height: 16rem;
    width: calc(49% - 13px);
    float:left;
    margin-left:0.5rem;
    margin-right:0.5rem;
    list-style:none;
  }

}

@media only screen and (max-width: 540px) {
  .landing-list__item .landing-list__background-img img {
    margin-top: -10%;
  }

  /* 1 panels for mobile view */
  .landing-list--boxes .landing-list__item {
    height: auto;
    width: auto;
    float:none;
  }

}

@media only screen and (max-width: 430px) {
  .landing-list__item .landing-list__background-img img {
    margin-top: 0;
  }
}

.landing-list__h {
  display: inline;
  text-transform: none;
  font-size: 1.2em;
  margin-bottom: 0.5em;
  border-bottom: 1px solid #c7d7e9;
  transition: border-bottom 0.2s, opacity 0.25s;
}

.landing-list__link:focus .landing-list__h,
.landing-list__link:hover .landing-list__h {
  border-bottom: 1px solid $marigold;
}

.landing-list--boxes .landing-list__link:focus .landing-list__h,
.landing-list--boxes .landing-list__link:hover .landing-list__h {
  border: none;
}

.landing-list__link.absolute_panel {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -40px;
  .landing-list__h.landing-list__new-h {
    display: inline-block;
    width: auto;
    padding: 5px 5px 0 5px;
    height: auto;
    border-bottom: 2px solid #ffc107;
  }
}

.landing-list--boxes .landing-list__h {
  border: none;
  color: $black;
  display: block;
  font-size: 1.125rem;
}

.landing-list--boxes .landing-list__new-h {
  color: $black;
  // position: absolute;
  bottom: -34px;
  width: 100%;
  left: 0;
}

.landing-list__item--centered .landing-list__h {
  text-align: left;
}

.landing-list__item__details .landing-list__h {
  text-align: left;
}

.landing-list--boxes .landing-list__item:hover .landing-list__item__summary .landing-list__h {
  opacity: 0;
}

.landing-list__content {
  color: #262626;
  display: block;
  font-weight: 400;
  margin-top: 1em;
  margin-bottom: 2em;
  font-size: 0.95em;
  line-height: 1.5;
}

.lt-ie9 .landing-list__content {
  font-weight: normal;
  font-family: "proxima-nova-n4", "proxima-nova", sans-serif;
}

.columned .landing-list__content {
  margin-bottom: 1em;
}

.reduced-padding .landing-list__content {
  font-weight: 300;
}

.lt-ie9 .reduced-padding .landing-list__content {
  font-weight: normal;
  font-family: "proxima-nova-n3", "proxima-nova", sans-serif;
}

.landing-list--boxes .landing-list__content {
  color: $black;
  font-size: 0.85em;
  font-weight: 300;
}

.landing-list__item--centered .landing-list__content {
  text-align: left;
}