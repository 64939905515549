/* Module: S */
.content--search {
    margin: 0 pxToRem(-40) pxToRem(-50) pxToRem(-40);
    padding: 0 pxToRem(40) pxToRem(30) pxToRem(40);
    flex-flow: row;
    justify-content: space-between;
}

.form--site-search {
    flex-flow: row;
    justify-content: space-between;

    .search {
        padding-bottom: pxToRem(20);
        flex-grow: 1;

        input[type="text"] {
            font-size: pxToRem(18);
            padding: pxToRem(22) pxToRem(80) pxToRem(22) pxToRem(30);
        }

        button {
            padding: pxToRem(21) pxToRem(30);

            svg {
                width: pxToRem(25);
                height: pxToRem(25);
            }
        }
    }
}

.search__header {
    font-size: pxToRem(50);
}

.search__list {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    background-color: $white-two;
    .mb-display span {
        display: none;
    }
}

.search__item {
    border-bottom-width: pxToRem(10);
    margin-bottom: pxToRem(20);
    padding: pxToRem(30);
    background-color: $white;
}

.search__date {
    background-color: $marigold;
    border-bottom: pxToRem(5) solid $black-two;
    width: pxToRem(68);
    height: pxToRem(70);
    text-align: center;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    line-height: 1;
    display: flex;
}

.search__day {
    font-size: pxToRem(28);
    display: block;
}

.search__month {
    font-size: pxToRem(14);
    display: block;
}

h2.search__title {
    font-size: pxToRem(26);
    font-weight: 500;

}

.search__type {
    font-weight: 600;
    font-size: pxToRem(13);
}

.search__desc {
    font-size: pxToRem(16);
    line-height: 1.5;
    margin-bottom: pxToRem(30);
}


.search__meta {
    span {
        display: inline-block;
        padding-left: pxToRem(15);
    }

    a {
        margin-left: pxToRem(32);

        &.search__more {
            margin-left: 0;
            margin-right: pxToRem(32);

            span {
                padding-left: 0;
                padding-right: pxToRem(15);
            }
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }

        svg {
            position: relative;
            top: pxToRem(3);
        }
    }
}