/* Module: S */
.header-quick-links {
    border-top: pxToRem(1) solid #888;
    border-bottom: pxToRem(1) solid #888;
    width: 100%;
    order: 2;
}

.header-quick-links__list {
    margin: 0;
    padding: pxToRem(15) pxToRem(7);
    list-style-type: none;
    font-size: pxToRem(15);
    line-height: 2.25;
}

.header-quick-links__link {
    text-decoration: none;
    color: $greyish-brown;
}