/* Module: S */
.curr-cons {
    text-align: center;
    padding-top: pxToRem(30);
    padding-bottom: pxToRem(30);
    background-color: $white;
    margin-left: pxToRem(15);
    margin-right: pxToRem(15);
    margin-bottom: pxToRem(30);
}

.curr-cons__icon {
    display: none;
}

.curr-cons__wrapper {
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
    max-width: pxToRem(660);
    margin-left: auto;
    margin-right: auto;
}

.curr-cons__heading {
    font-weight: 400;
    font-size: pxToRem(18);
    position: relative;
    display: inline-block;
    padding-bottom: pxToRem(3);
}

.curr-cons__heading::after {
    content: '';
    display: block;
    width: 100%;
    height: pxToRem(2);
    background-color: $marigold;
    position: absolute;
    left: 0;
    bottom: 0;
}

.curr-cons__sub-heading {
    line-height: 1.29;
    font-size: pxToRem(28);
    font-weight: 400;
    display: block;
    text-decoration: none;
    color: $black;
    &:hover{
        text-decoration: underline;
    }
}

.curr-cons__info {
    font-size: pxToRem(16);
    color: $greyish-brown;
    line-height: 1.5;
    text-align: center;
}

.curr-cons__status-box {
    max-width: pxToRem(580);
    margin-left: auto;
    margin-right: auto;
    margin-top: pxToRem(50);
}

.curr-cons__days-remaining {
    text-transform: uppercase;
    color: $black;
    line-height: 1.08;
    font-size: pxToRem(12);
    text-align: right;
}

.curr-cons__days-remaining strong {
    line-height: 0.5;
    font-weight: 600;
    font-size: pxToRem(28);
    margin-right: pxToRem(5);
}

.progress-bar {
    height: pxToRem(5);
    background-color: $pinkish-grey;
    margin-top: pxToRem(20);
    margin-bottom: pxToRem(10);
}

.progress-bar__progress {
    background-color: $black;
    height: 100%;
}

.curr-cons__meta {
    font-size: pxToRem(13);
    line-height: 1.08;
    color: $greyish-brown;
    text-align-last: left;
}

.curr-cons__status strong {
    font-weight: 600;
}