/* Module: S */
.home-mega-grid__wrapper {
    background-color: $white-two;
    padding: pxToRem(15) pxToRem(15) pxToRem(45) pxToRem(15);
}

.home-mg__header.visuallyhidden {
}

.home-mg__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.home-mg__item {
    display: none;
    margin-bottom: pxToRem(10);
    background-color: $black;
    color: $white;
    position: relative;

    >a {
        background-color: $black;
    }

    a {
        color: $white;
    }
}

.home-mg__item.home-mg__item--promoted {
    background-color: $marigold;

    >a {
        background-color: $marigold;
    }

    a {
        color: $black-two;
    }
}

.home-mg__item.home-mg__item--social {
    background-color: $white;

    > a {
        background-color: $white;
        color: $black-two;
        border-bottom: 3px solid $black-two;
    }

    &-instagram {
        > a {
            border-bottom: none;
        }
    }

    a {
        color: $black-two;
    }
}

// when promoted or social tile
.home-mg__item.home-mg__item--promoted,
.home-mg__item.home-mg__item--social,
.home-mg__item.home-mg__item--promoted .home-mg__link,
.home-mg__item.home-mg__item--social .home-mg__link {
    color: $black;
}

// when promoted or social tile with image
.home-mg__item--promoted .home-mg__image-wrapper + .home-mg__meta,
.home-mg__item--social .home-mg__image-wrapper + .home-mg__meta {
    color: $white;
}

.home-mg__item:nth-of-type(1),
.home-mg__item:nth-of-type(2),
.home-mg__item:nth-of-type(3),
.home-mg__item:nth-of-type(4),
.home-mg__item:nth-of-type(5) {
    display: block;
}

.home-mg__link {
    display: block;
    text-decoration: none;
    color: $white;
}

.home-mg__image-wrapper::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    max-height: pxToRem(230);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), $black);
}

.home-mg__item--video .home-mg__image-wrapper::before {
    content: '';
    display: block;
    width: pxToRem(68);
    height: pxToRem(69);
    background-image: url({{file_dest}}/icon-play.png);
    background-repeat: no-repeat;
    background-position: 0 0;
    position: absolute;
    top: calc(50% - 34px);
    left: calc(50% - 34px);
    z-index: 3;
}

.home-mg__item--video .home-mg__link {
    display: none;
}

.home-mg__item--video .home-mg__link.mobile-only {
    display: block;
    border: none;
    height: pxToRem(290);
}

.home-mg__item--social-instagram .home-mg__image-wrapper::before {
    content: '';
    display: block;
    width: 100%;
    height: pxToRem(80);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-image: linear-gradient(to bottom, $black, rgba(0, 0, 0, 0));
}

.home-mg__item--social-instagram .home-mg__image-wrapper::after {
    height: pxToRem(108);
}

.home-mg__image {
    height: pxToRem(290);
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    position: relative;
    z-index: 1;
    display: block;
}

.home-mg__meta {
    padding: pxToRem(20) pxToRem(22);
}

.home-mg__image-wrapper + .home-mg__meta {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 2;
    left: 0;
}

.home-mg__category {
    font-size: pxToRem(13);
    font-weight: 600;
    color: $black-two;
    background-color: $marigold;
    letter-spacing: pxToRem(.2);
    display: block;
    padding: pxToRem(5) pxToRem(9);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    text-transform: uppercase;
}

.home-mg__item .icon-social {
    position: absolute;
    top: pxToRem(20);
    right: pxToRem(20);
    height: pxToRem(20);
    z-index: 3;
}

.home-mg__item--news .home-mg__meta {
    padding-top: pxToRem(40);
}

.home-mg__item--social .home-mg__account {
    color: $warm-grey-three;
}

.home-mg__item--social-instagram .icon-social {
    fill: $white;
}

.home-mg__item--social-instagram .home-mg__meta {
    top: 0;
    padding: 0;
}

.home-mg__item--social-instagram .home-mg__date {
    position: absolute;
    top: pxToRem(20);
    left: pxToRem(20);
    padding: 0;
}

.home-mg__item--social-instagram .home-mg__account {
    position: absolute;
    bottom: pxToRem(20);
    left: pxToRem(20);
    padding: 0;
    color: $white;
}

.home-mg__date {
    font-size: pxToRem(14);
    font-weight: 600;
    line-height: 1.4;
    text-transform: uppercase;
    display: block;
    padding-top: pxToRem(10);
}

.home-mg__desc {
    font-size: pxToRem(15);
    line-height: 1.27;
    margin: 0;
    padding-top: pxToRem(10);
    max-height: pxToRem(105);
    overflow: hidden;
}

.home-mg__name {
    font-size: pxToRem(30);
    line-height: 1;
    margin: 0;
    padding: pxToRem(10) 0;
}

.home-mg__item--fact .home-mg__name {
    color: $marigold;
}

.home-mg__account {
    font-size: pxToRem(14);
    line-height: 1.62;
    padding-top: pxToRem(25);
    display: block;
    text-transform: uppercase;
}

.home-mg__social {
    @extend %screen-hide;
}

.btn {
}

.home-mg__button-more {
    display: block;
    margin: pxToRem(40) auto 0;
}