/* Module: S */
.landing-cta-tiles {
    padding-bottom: 0;
    background: $white-two;
    margin-top: pxToRem(25);
    padding-top: pxToRem(50);

    .content & {
        padding: 0;
    }
}

.landing-cta__container {
    width: pxToRem($container-width-desktop);
    margin-left: auto;
    margin-right: auto;

    .content & {
        width: 100%;
    }
}

.landing-cta-tiles .landing-container {
    padding-left: pxToRem(0);
    padding-right: pxToRem(0);
}

.landing-cta-tiles__list {
    display: flex;
    justify-content: space-between;
}

.landing-cta-tiles__item {
    width: calc(50% - 15px);
    .content & {
        margin-bottom: 0;
    }
}

.landing-cta-tiles__image-wrapper {
}

.landing-cta-tiles__image {
}

.landing-cta-tiles__meta {
    padding: pxToRem(30);
}

.landing-cta-tiles__title {
    padding-right: 0;
    font-size: pxToRem(28);
    margin-bottom: pxToRem(8);
}

.landing-cta-tiles__title::after {
    height: pxToRem(3);
}

.landing-cta-tiles__link {
}

.landing-cta-tiles__desc {
    font-size: pxToRem(16);
    margin-bottom: pxToRem(20);
}

.landing-cta-tiles__more .icon-arrow-right {
    transition: .1s transform ease;
}

.landing-cta-tiles__item:hover .icon-arrow-right {
    transform: translateX(10px);
}

.svg-icon.icon-arrow-right {
}