/* Module: S */
.back-top {
    right: pxToRem(30);

    a {
        height: pxToRem(25);
    }

    .icon-arrow-right {
        width: pxToRem(25);
        height: pxToRem(25);
        padding: pxToRem(7);
    }

    span {
        display: block;
        float: left;
        font-size: pxToRem(12);
        line-height: pxToRem(25);
        font-weight: 600;
        color: $black-two;
        padding-left: pxToRem(6);
    }
}