/* Module: S */
.content--news-events {
    flex: 1;
    padding-right: pxToRem(30);
}

.news-events {
    padding-bottom: pxToRem(20);
    width: 100%;
}

.news-events__header {
    font-size: pxToRem(50);
}

.news-events__list {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

.news-events__item {
    border-bottom-width: pxToRem(10);
    margin-bottom: pxToRem(20);
    padding: pxToRem(30) pxToRem(30) pxToRem(30) pxToRem(125);

    .perform & {
        margin-bottom: pxToRem(65);
    }
}

.news-events__date {
    background-color: $marigold;
    border-bottom: pxToRem(5) solid $black-two;
    width: pxToRem(68);
    height: pxToRem(70);
    text-align: center;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    line-height: 1;
    display: flex;
    top: pxToRem(30);
    left: pxToRem(30);
}

.news-events__day {
    font-size: pxToRem(28);
    display: block;
}

.news-events__month {
    font-size: pxToRem(14);
    display: block;
}

.news-events__title {
    font-size: pxToRem(26);
    font-weight: 500;
}

.news-events__link {
    font-size: pxToRem(26);
}

.news-events__type {
    font-weight: 600;
    font-size: pxToRem(13);
    display: inline-block;
}

.news-events__address {
    margin: 0 0 0 pxToRem(20);
    display: inline-block;
}

.news-events__desc {
    font-size: pxToRem(16);
    line-height: 1.5;
    margin-bottom: pxToRem(30);
    display: block;
}

.news-events__location {
    font-size: pxToRem(13);
}

.news-events__meta {
    display: flex;
    position: relative;
    justify-content: flex-end;
}

.news-events__more {
    position: absolute;
    left: 0;
    font-size: pxToRem(14);
    font-weight: 600;
    padding-right: pxToRem(10);
    width: auto;
    height: auto;
    text-indent: 0;
}

.news-events__more .icon-arrow-right {
    transition: .2s ease transform;
    top: 0;
    margin-left: pxToRem(10);
    display: inline;

}

.news-events__more:hover .icon-arrow-right {
    transform: translateX(10px);
}

.news-events__copy-link {
    margin-right: pxToRem(20);
    font-size: pxToRem(14);
    font-weight: 600;
    display: block;
}

.news-events__email-link {
    font-size: pxToRem(14);
    font-weight: 600;
    display: block;
}