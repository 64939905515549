/* Module: S */
.share-bar--long-page {
  padding-left: pxToRem($padding-global);
  padding-right: pxToRem($padding-global);
}

.share-bar__header {
  font-size: pxToRem(18);
  font-weight: 600;
  color: $black-two;
  margin-bottom: pxToRem(10);
}

.share-bar__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.share-bar__item {
  display: inline-block;
}

.share-bar__link {
  display: inline-block;
  height: pxToRem(25);
  padding-left: pxToRem(13);
  padding-right: pxToRem(13);
}

.share-bar__link:first-of-type {
  padding-left: 0;
}

.icon-social-facebook {
  width: pxToRem(11);
  height: pxToRem(20);
}

.icon-social-instagram {
  width: pxToRem(18);
  height: pxToRem(18);
}

.icon-social-twitter {
  width: pxToRem(21);
  height: pxToRem(17);
}

.icon-social-youtube {
  width: pxToRem(24);
  height: pxToRem(17);
}

.icon-social-linkedin {
  width: pxToRem(22);
  height: pxToRem(21);
}
