/* Module: S */
.home-investigations {
    &__wrapper {
        width: pxToRem(1140);
        margin-left: auto;
        margin-right: auto;
        border-bottom: pxToRem(2) solid $pinkish-grey;
        padding-top: pxToRem(10);        
        padding-bottom: pxToRem(10);        
    }
}

.home-investigations__header {
    font-size: pxToRem(18);
    padding-bottom: pxToRem(40);
}

.home-investigations__main-name {
    font-size: pxToRem(50);
}

.home-investigatons__list {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
}

.home-investigations__item {
    width: calc(33% - 15px);
    border-bottom-width: pxToRem(10);
    transition: .1s transform ease-in;
}

.home-investigations__item:hover {
    transform: scale(1.05);
}

.home-investigations__item-link {
    min-height: pxToRem(300);
    padding: pxToRem(57) pxToRem(30) pxToRem(29);
}

.home-investigations__name {
    font-size: pxToRem(32);
    line-height: 1.25;
    font-weight: 500;
}

.home-investigations__more {
    bottom: pxToRem(29);
    left: pxToRem(30);
    width: auto;
}

.home-investigations__more .icon-arrow-right {
    margin-left: pxToRem(12);
    position: relative;
    top: pxToRem(2);
}

.home-investigations__all {
    margin-top: pxToRem(45);
    margin-bottom: pxToRem(45);
}