/* Module: S */
.p-perform-infograph {
  padding: 0 pxToRem(20) pxToRem(20);
  margin-top: pxToRem(20);
  background: $marigold;
 
  &__title {
    padding: pxToRem(15) 0 0 0;
    font-size: pxToRem(24);
    text-align: center;
  }

  &__items {
    @include flexbox();
    @include justify-content(center);
    @include flex-direction(column);
    padding: pxToRem(30) 0 pxToRem(10) 0;
  }
  
  &__item {
    margin-bottom: pxToRem(10);
    color: $black;
    text-decoration: none;
    &:list-child {
      margin-bottom: 0;
    }
  }

  &__tile {
    width: 100%;
    @include flexbox();
    @include justify-content(start);
    padding: pxToRem(13);
    background-color: $white;
  }

  &__img {
    width: pxToRem(48);
  }

  &__img img {
    height: pxToRem(48);
    width: auto;
  }

  &__content {
    margin-left: pxToRem(10);
    h1 {
      font-size: pxToRem(16);
      margin: pxToRem(5) 0 0 0 ;
      font-weight: normal;
      color: #181818;
    }
    p {
      margin: 0;
      font-size: pxToRem(16);
      font-weight: bold;
      line-height: pxToRem(12);
    }
  }
}