/* Module: S */
.header-wrapper {
    height: pxToRem(70);
}

.header-main {
    height: pxToRem(70);
    width: 100%;
    background: $white;

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 5;
        display: none;

        &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            height: 1px;
            background-color: #e4e4e4;
        }
    }
}

.header-content {
    position: relative;
    height: pxToRem(70);
}

.header-logo {
    display: inline-block;
    position: relative;
    margin-top: 14px;
}

.header-logo__wrapper {
    text-decoration: none;
}

.header-logo__image {
    // width: pxToRem(132);
    width: auto;
    height: pxToRem(36);
    // position: absolute;
    // left: pxToRem($padding-global);
    // top: pxToRem(17);
    margin-left: pxToRem(10);
}

.header-logo__font {
    width: pxToRem(96);
    height: pxToRem(16);
    margin-left: pxToRem(1);
}

.header-links__toggle {
    width: pxToRem(22);
    height: pxToRem(22);
    border: none;
    padding: 0;
    position: absolute;
    right: pxToRem($padding-global);
    top: pxToRem(26);
    background-color: transparent;
}
.header-links__toggle[aria-expanded='false'] + .header-links__wrapper {
    display: none;
}

.header-links__toggle .icon-toggle-cross {
    display: block;
}

.header-links__toggle[aria-expanded='false'] .icon-toggle-cross {
    display: none;
}

.header-links__toggle .icon-toggle-burger {
    display: none;
}

.header-links__toggle[aria-expanded='false'] .icon-toggle-burger {
    display: block;
}

.header-links__wrapper {
    background: $white;
    position: absolute;
    top: 70px;
    width: calc(100% + 17px);
    left: pxToRem(-10);
    border-top: pxToRem(1) solid #888;
    display: flex;
    flex-wrap: wrap;
    padding: 0 pxToRem(15);
    z-index: 4;
}

.header-google-translate {
    width: 100%;
    order: 3;
    padding-top: pxToRem(23);
    padding-bottom: pxToRem(23);
    .goog-te-combo {
        border: 0.1rem solid #ECECEC;
        padding: 0.2rem 0.2rem !important;
    }
}