/* Module: S */
.risk-predictor {

	&__selector {
		margin-top: pxToRem(-90);
		padding-right: pxToRem(212);
		padding-bottom: 0;
	}

	&__container {
		h3.title,
		h3.risk-predictor__info {
			padding-top: pxToRem(10);
		}

		ul {
			margin: 0 pxToRem(-37);
			padding: pxToRem(10) 0;
		}

		li {
			border-bottom: none;
			padding: 0 pxToRem(38);
			width: auto;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: pxToRem(12);
				bottom: pxToRem(6);
				left: pxToRem(-1);
				width: pxToRem(2);
				background-color: $warm-grey;
			}

			&:first-child {
				&:before {
					display: none;
				}
			}
		}
	}

	&__select {
		border-right: pxToRem(2) solid $black;
		border-left: none;
		

		select {
			padding: pxToRem(38) pxToRem(75) pxToRem(37) pxToRem(54);
		}
	}

	&__select + &__select {
		border-right: 0;
	}

	&__submit {
		top: 0;
		left: auto;
		right: 0;
		margin-left: 0;
		padding: 0;
		border-bottom: pxToRem(5) solid $black;

		input[type="submit"] {
			border-radius: 0;
			width: 100%;
			height: 100%;
			font-size: pxToRem(18);
		}
	}

	&__item {
		background-repeat: no-repeat;

		&.biological-factors {
			background-image: url(../mysource_files/icon-biological.svg);
			background-position: pxToRem(59) pxToRem(38);
			background-size: pxToRem(84);
		}

		&.body-stressing {
			background-image: url(../mysource_files/icon-body-stress.svg);
			background-position: pxToRem(60) pxToRem(38);
			background-size: pxToRem(76);
		}

		&.chemicals-and-other-substances {
			background-image: url(../mysource_files/icon-chemicals.svg);
			background-position: pxToRem(59) pxToRem(38);
			background-size: pxToRem(84);
		}

		&.heat-electricity-and-other-environmental-factors {
			background-image: url(../mysource_files/icon-electricity.svg);
			background-position: pxToRem(41) pxToRem(40);
			background-size: pxToRem(121);
		}

		&.falls-trips-and-slips-of-a-person {
			background-image: url(../mysource_files/icon-falls.svg);
			background-position: pxToRem(46) pxToRem(40);
			background-size: pxToRem(111);
		}

		&.being-hit-by-moving-objects {
			background-image: url(../mysource_files/icon-hit.svg);
			background-position: pxToRem(54) pxToRem(37);
			background-size: pxToRem(77);
		}

		&.hitting-objects-with-a-part-of-the-body {
			background-image: url(../mysource_files/icon-hitting-object.svg);
			background-position: pxToRem(48) pxToRem(38);
			background-size: pxToRem(94);
		}

		&.mental-stress {
			background-image: url(../mysource_files/icon-mental-stress.svg);
			background-position: pxToRem(50) pxToRem(38);
			background-size: pxToRem(56);
		}

		&.other-and-unspecified-mechanisms-of-incident {
			background-image: url(../mysource_files/icon-other.svg);
			background-position: pxToRem(59) pxToRem(38);
			background-size: pxToRem(84);
		}

		&.sound-and-pressure {
			background-image: url(../mysource_files/icon-sound.svg);
			background-position: pxToRem(50) pxToRem(38);
			background-size: pxToRem(70);
		}

		&.vehicle-incidents-and-other {
			background-image: url(../mysource_files/icon-vehicles.svg);
			background-position: pxToRem(41) pxToRem(40);
			background-size: pxToRem(134);
		}

		a {
			padding: pxToRem(36) pxToRem(36) pxToRem(36) pxToRem(200);

			&:hover,
			&:focus {
				.risk-predictor {
					&__link {
						.icon-arrow-right {
							transform: translateX(10px);
						}
					}
				}
			}
		}
	}

	&__info {
		padding-bottom: pxToRem(20);
	}

	&__period {
		text-transform: uppercase;
		font-size: pxToRem(12);
		line-height: 1.5;
		display: inline-block;
		padding: 0 pxToRem(5);
	}

	&__data {
		font-size: pxToRem(50);
		line-height: 1.2;
		padding-right: 0;
	}

	&__percentage {
		font-size: pxToRem(18);
		padding: pxToRem(28) 0 pxToRem(7) pxToRem(10);
	}

	&__label {
		clear: left;
		padding: 0;
	}

	&__link .icon-arrow-right {
	    top: 0;
	    margin-left: pxToRem(10);
	    transition: .2s ease transform;
	}
  
  .filters__select-list { 
  	// left: pxToRem(-13);
  	top: pxToRem(114);
  }

	span.init {
		display: block;
		padding: pxToRem(38) pxToRem(75) pxToRem(37) pxToRem(54);
		font-size: pxToRem(18);
		height: pxToRem(125);
	}
}