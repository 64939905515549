/* Module: S */
.section {
	background-size: cover;
	background-position: center;
	position: relative;
	@include font-smoothing;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba($black, 0.8);
	}


	h1 {
		color: $white;
		font-size: pxToRem(26);
		font-weight: bold;
		line-height: 1.11;
		text-transform: none;
		margin: 0;
		margin-bottom: pxToRem(-20);
	}

	h2 {
		color: $white;
		font-size: pxToRem(26);
		line-height: 1.31;
		font-weight: bold;
		text-transform: none;
		margin: 0;
		margin-bottom: pxToRem(-10);
	}

	p {
		font-size: pxToRem(14);
		line-height: 1.57;
		color: $white;
		margin: 0;
		padding-top: pxToRem(30);

		&:first-child {
			padding-top: 0;
		}

	}

	&__links {
		@extend %clearfix;
		padding-top: pxToRem(30);
		margin-top: pxToRem(-24);

		a {
			display: block;
			float: left;
			// background-color: $greyish-brown;
			border-radius: pxToRem(23);
			font-size: pxToRem(14);
			// font-weight: 500;
		    line-height: 1.6;
			// color: $white;
			border: none;
			padding: pxToRem(10) pxToRem(20);
			margin-top: pxToRem(24);
			clear: left;
			color: $black;
			background-color: $marigold;
			font-weight: 600;
			text-decoration: none;
			@include sq-transition(background-color 0.3s);

			&:hover {
				background-color: $frangipani;
			}
		}
	}

	&__wrapper {
		max-width: pxToRem(850);
		margin: 0 auto;
		position: relative;
		padding: pxToRem(60) pxToRem(30);
	}

	&-banner {
		text-align: center;

		&:before {
			background-color: $white-two;
		}

		h1,
		h2 {
			color: $black;
		}

		p {
			color: $brownish-grey;
		}

		.section {
			&__wrapper {
				max-width: pxToRem(660);
				padding-top: pxToRem(40);
				padding-bottom: pxToRem(40);
			}
		}
	}

	&-hidden {
		display: none;
	}

	&-disclaimer {
		&:before {
			background-color: $white-two;
		}

		h2 {
			color: $black;
			font-size: pxToRem(16);
			font-weight: normal;
		}

		p {
			color: $brownish-grey;
		}

		a {
			color: $brownish-grey;
			border: none;
			font-weight: normal;

			&:hover {
				text-decoration: underline;
			}
		}

		.section {
			&__wrapper {
				padding: pxToRem(60) pxToRem(15);
				max-width: none;
			}
		}

	}
}