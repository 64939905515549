/* Module: S */
.consultations {
    padding-top: pxToRem(40);
    padding-bottom: pxToRem(50);
}

.consultations__list {
    position: relative;
    width: pxToRem(1140);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.consultations__item {
    width: pxToRem(360);
}

.consultations__image-wrapper {
}

.consultations__category {
    width: pxToRem(78);
    height: pxToRem(78);
}

.consultations__image {
    width: 100%;
    height: pxToRem(270);
    object-fit: cover;
}

.consultations__meta {
    border-bottom-width: pxToRem(10);
}

.consultations__name {
    margin-bottom: 0
}

.consultations__link {
}

.consultations__desc {
    margin-top: pxToRem(8);
    margin-bottom: pxToRem(20);
}

.consultations__more {
}

.consultations .button__container {
    margin-top: pxToRem(9);
}
.consultations  .btn--arrow-down {
    margin-bottom: 0;
}