/* Module: S */
.landing-container,
.resources-topic__container {
    padding-left: pxToRem($padding-global);
    padding-right: pxToRem($padding-global);

    &__wrapper {
        background-color: $white;
        padding: pxToRem(10) 0 pxToRem(35) 0;
    }
}

.landing-content {
    h2 {
        font-size: pxToRem(36);
        font-weight: 500;
        line-height: 1.29;
        padding-bottom: pxToRem(8);
        position: relative;
        display: inline-block;
        margin-bottom: pxToRem(10);
    }

    h2::after {
        content: '';
        display: block;
        width: 100%;
        height: pxToRem(3);
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $marigold;
    }
    p {
        font-size: pxToRem(18);
        line-height: 1.56;
        color: $greyish-brown;
    }
    .landing__content-more {
        font-size: pxToRem(13);
        text-decoration: none;
        color: $black-two;
    }
    
    .landing__content-link {
        color: $black;
    }

    .landing__content-more .icon-arrow-right {
        width: pxToRem(17);
        height: pxToRem(11);
        margin-left: pxToRem(8);
    }
}