/* Module: S */
.landing-listing-simple {
    color: $black-two;
    text-align: center;

    &__wrapper {
        background-image: url('{{file_dest}}/landing-art-bg-temp.png');
        background-position: center center;
        background-repeat: repeat;
        padding: pxToRem(15) 0;
    }
}


.landing-listing-simple__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: left;
}

.landing-listing-simple__item {
    padding: pxToRem(20);
    position: relative;
    border-bottom: pxToRem(3) solid $black;
    background-color: $white;
    margin-bottom: pxToRem(10);
}

.landing-listing-simple__title {
    font-size: pxToRem(18);
    font-weight: 600;
    margin: 0;
    position: relative;
    display: inline-block;
    margin-bottom: pxToRem(10);
    padding-bottom: pxToRem(8);
}
.landing-listing-simple__title::after {
    content: '';
    display: block;
    width: 100%;
    height: pxToRem(3);
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $marigold;
}

.landing-listing-simple__link {
    text-decoration: none;
    color: $black-two;
}

.landing-listing-simple__type {
    margin-top: pxToRem(5);
    margin-bottom: pxToRem(10);
}

.landing-listing-simple__desc {
    line-height: 1.27;
    font-size: pxToRem(15);
    color: $greyish-brown;
    margin: 0 0 pxToRem(30);

    & > h1:first-child,
    & > h2:first-child,
    & > h3:first-child,
    & > h4:first-child,
    & > h5:first-child,
    & > p:first-child,
    & > ul:first-child,
    & > ol:first-child {
        margin-top: 0;
    }
}



.landing-listing-simple__more-button {
    display: inline-block;
    margin: pxToRem(27) auto;
    text-decoration: none;
    color: $black-four;
}

.landing-listing-simple__more {
    text-decoration: none;
    font-size: pxToRem(13);
    color: $black-two;
    display: block;
}

.landing-listing-simple__more .icon-arrow-right {
    width: pxToRem(17);
    height: pxToRem(11);
    margin-left: pxToRem(10);
}
