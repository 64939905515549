/* Module: S */
.resources-listing  { 
  
  .main > h1 {
    display: none;
  }

  .main >h1.library__title {
    display: block;
    margin-bottom: pxToRem(52);
  }
  
  .search__resources__sort {

    .sq-form-question-title {
      font-size: pxToRem(16);
      font-weight: 400;
      display: block;
      text-align:left;
    }

    .sq-form-question-answer { 
      width: 100%;
    }

    .search__resources__select {
      height: pxToRem(60);
      background-color: #ffc107;
    }
  }
  .resources__list {
    .search__title {
      margin-bottom: pxToRem(10);
    }
    
    .search__link {
      font-size: pxToRem(20);
      font-weight: 400;
    }

    .search__copy-link,
    .search__email-link {
      font-size: pxToRem(14);
    }
  }
  
  .search__resources {
    &-summary {
      margin-top: pxToRem(20);
      text-align:left;
      margin-bottom: pxToRem(20);
    }


    &__sort { 
      .sq-form-question-answer {
        display: inline-block;
      }

      select {
        border-bottom: none;
      }
    }
  }

  .search__meta {

    &-summary {
      position: absolute;
      left: 0;
      margin-top: pxToRem(7);
    }

    &-date,
    &-type {
      font-size: pxToRem(14);
      color: $warm-grey-three;
      line-height: 0.75rem;
      display: inline-block !important;
    }


    &-date {
      padding-left: 0 !important;
      border-right: 1px solid;
      padding-right: pxToRem(12);
    }

    &-type {
      padding-left: pxToRem(8) !important;
    }
  }

  .c-pagination {
    list-style: none;
    padding-left: 0;
    text-align: center;

    margin-top: pxToRem(25);
    &__item {
      display: inline-block;
      line-height: 1.38;
      margin: 0 pxToRem(5);
      
      &.is-active {
        .c-pagination__link {
          font-weight: bold;
          color: $black;
        }
      }
    }

    &__link {
      color: #6b6b6b;
      text-decoration: none;
    }

    &__arrow {
      text-transform: uppercase;
      color: $black;
    }

    &__arrow--next {
      padding-left: pxToRem(16);
    }

    &__arrow--before {
      padding-right: pxToRem(16);
    }
  }
}
