/* Module: S */
.home-mega-grid__wrapper {
    padding-top: pxToRem(40);
    padding-bottom: pxToRem(60);
}

.home-mega-grid {
    margin-left: auto;
    margin-right: auto;
    width: pxToRem(1140);

    + .home-mega-grid {
        padding-top: pxToRem(30);
    }
}

.home-mg__list {
    display: grid;
    display: -ms-grid;
    grid-template-columns: $hmg-col $hmg-col $hmg-col $hmg-col;
    grid-template-rows: $hmg-row $hmg-row $hmg-row $hmg-row;
    -ms-grid-columns: $hmg-col $hmg-gap $hmg-col $hmg-gap $hmg-col $hmg-gap $hmg-col;
    -ms-grid-rows: $hmg-row $hmg-gap $hmg-row $hmg-gap $hmg-row $hmg-gap $hmg-row;
    grid-gap: $hmg-gap;
 
    &:nth-child(3) {
        margin: pxToRem(31) 0;
    }
}

.home-mg__item {
    display: block;
}

.home-mg__item:nth-of-type(1) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    -ms-grid-column: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
}

.home-mg__item:nth-of-type(2) {
    -ms-grid-column: 3;
    -ms-grid-row: 1;
}

.home-mg__item:nth-of-type(3) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 3;
    -ms-grid-column: 5;
    -ms-grid-row: 1;
    -ms-grid-column-span: 3;
    -ms-grid-row-span: 3;
}

.home-mg__item:nth-of-type(4) {
    -ms-grid-column: 1;
    -ms-grid-row: 5;
}

.home-mg__item:nth-of-type(5) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
    -ms-grid-column: 3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 3;
}

.home-mg__item:nth-of-type(6) {
    -ms-grid-column: 5;
    -ms-grid-row: 5;
}

.home-mg__item:nth-of-type(7) {
    -ms-grid-column: 7;
    -ms-grid-row: 5;
}

.home-mg__item:nth-of-type(8) {
    -ms-grid-column: 1;
    -ms-grid-row: 7;
}

.home-mg__item:nth-of-type(9) {
    -ms-grid-column: 3;
    -ms-grid-row: 7;
}


.home-mg__item:nth-of-type(10) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 5;
    -ms-grid-column: 5;
    -ms-grid-row: 7;
    -ms-grid-column-span: 3;
}

.home-mg__link {
    height: 100%;
    transition: .2s transform ease;

    &:hover {
        transform: scale(1.05);
    }
}

.home-mg__meta {
    max-height: pxToRem(200);
    overflow: hidden;
}

.home-mg__item--video {
    .home-mg__link {
        &:hover {
            .home-mg__image-wrapper::before {
                background-image: url({{file_dest}}/icon-play-hover.png);
            }
        }
    }   
}

.home-mg__item.home-mg__item--social {
    > a {
        border-bottom-width: 10px;
    }
}

.home-mg__image-wrapper {
    height: 100%;
}

.home-mg__image {
    height: 100%;
}

.home-mg__name {
   padding-bottom: 0;
}

.home-mg__account {
    padding-top: pxToRem(15);
}

.home-mg__item--video .home-mg__link {
    display: block;
}

.home-mg__item--video .home-mg__link.mobile-only {
    display: none;
}