/* Module: S */
.hazards {

  .hazards__list {

    @include flexbox();
    @include justify-content(space-between);
    
    margin-bottom: pxToRem(50);

    &-anchor {
      margin-right: pxToRem(32);
      margin-bottom: 0;
    }
    
    .news-events__list {
      position: relative;
    }
    .news-events__item {
      padding: pxToRem(30);
    }
  } 
}