/* Module: S */
.landing-cta-tiles {
    padding: pxToRem(30) pxToRem($padding-global) pxToRem(50);

    .content & {
        padding-left: 0;
        padding-right: 0;
    }
}

.landing-container {
}

.landing-cta-tiles__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.landing-cta-tiles__item {
    position: relative;
    margin-bottom: pxToRem(20);
}

.landing-cta-tiles__image-wrapper {
    position: relative;
}

.landing-cta-tiles__image-wrapper::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), $black);
    position: absolute;
    top: 0;
    left: 0;
    transition: .2s ease opacity;
}

.landing-cta-tiles__item:hover .landing-cta-tiles__image-wrapper::after {
    opacity: 0;
}

.landing-cta-tiles__image {
    display: block;
    width: 100%;
    min-height: pxToRem(220);
    object-fit: cover;
}

.landing-cta-tiles__meta {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    padding: pxToRem(20);
}

.landing-cta-tiles__title {
    padding-bottom: pxToRem(8);
    font-size: pxToRem(22);
    font-weight: 500;
    padding-right: pxToRem(16);
    position: relative;
    display: inline-block;
    margin-bottom: 0;
}

.landing-cta-tiles__title::after {
    content: '';
    display: block;
    width: 100%;
    height: pxToRem(2);
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $marigold;
}

.landing-cta-tiles__link {
    color: $white;
    text-decoration: none;
}

.landing-cta-tiles__desc {
    color: $white;
    line-height: 1.5;
    font-size: pxToRem(13);
    p,div {
        color: $white !important;
    }
}

.landing-cta-tiles__more {
    font-size: pxToRem(13);
    color: $white;
    text-decoration: none;
}

.landing-cta-tiles__more .icon-arrow-right {
    width: pxToRem(17);
    height: pxToRem(11);
    margin-left: pxToRem(8);
    fill: $marigold;
}