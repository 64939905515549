/* Module: S */
.hero {
    color: $black-two;
    background-color: $greyish-brown;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    width: 100%;
    text-align: center;
    padding: pxToRem(30) pxToRem(20) pxToRem(30);
    border-top: pxToRem(4) solid $black-two;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: $white-two;
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
    }

    h1 {
        position: relative;
        font-size: pxToRem(32);
        margin: 0;
    }


    h2 {
        position: relative;
        font-size: pxToRem(13);
        font-weight: 500;
        margin: 0;
    }

    .hazards & {
        padding:pxToRem(30) pxToRem(15);
    }
}

.hero__event-wrapper {
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
    text-align: left;

    h1 {
        font-weight: 500;
    }
}

.hero__event-details {
    font-size: pxToRem(20);
    position: relative;
    margin-bottom: pxToRem(-20);
}

.hero__event-details span {
    display: block;
}

.hero__event-details span:last-of-type::after {
    display: none;
}

.hero__event-location .icon-location {
    width: pxToRem(11);
    height: pxToRem(15);
    fill: $black;
    margin-right: pxToRem(8);
}

.hero__event-date-box {
    display: none;
}

.hazards {
    
    &__container {
        position: relative;
        width: 100%;
        margin-top: 50px;
    }

    &__item-container{
        display: none;
        position: relative;
    }
    
    &__item {
        display: inline-block;
        width: pxToRem(51);
        height: pxToRem(41);
        background-color: $white;
        margin-bottom: pxToRem(10);
        margin: pxToRem(4);
    }

    &__link {
        font-size: pxToRem(18);
        margin-top: pxToRem(11);
        display: inline-block;
        font-weight: bold;
        color: $black;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: $marigold;
        }
    }
    
    .filters__heading {
        font-size: pxToRem(18);
        margin-bottom: pxToRem(18);
    }

    .filters--foldable .filters__heading--active + .hazards__item-container {
         display: block;
         margin-bottom: pxToRem(10);
    }
}