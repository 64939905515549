/* Module: S */
.header-wrapper {
    background-color: $white;
    position: relative;
    height: pxToRem(170);
}

.header-main {
    background-color: $white;
    height: pxToRem(170);
    position: relative;

    &:before {
        content: '';
        display: block;
        height: pxToRem(1);
        background-color: #e4e4e4;
        bottom: pxToRem(70);
        position: absolute;
        left: 0;
        right: 0;
    }

    &.fixed {
        height: pxToRem(112);

        &:before {
            bottom: auto;
            top: pxToRem(41);
        }

        .header-logo {
            margin-top: pxToRem(9);
        }

        .header-content {
            height: pxToRem(112);
        }

        .header-google-translate {
            display: none;
        }

        .header-logo__image {
            height: pxToRem(24);
        }

        .header-quick-links {
            top: pxToRem(10);
            right: pxToRem(40);

            &:before {
                display: none;
            }
        }

        .quick-search__form {
            top: pxToRem(42);
        }
    }
}

.header-content {
    height: pxToRem(170);
    margin-left: auto;
    margin-right: auto;
    max-width: pxToRem(1140 + 80);
    padding-left: pxToRem(40);
    padding-right: pxToRem(40);
}

.header-logo__image {
    height: pxToRem(60);
    margin-left: 0;
}

.header-logo__font {
    width: pxToRem(128);
    height: auto;
    margin-left: pxToRem(4);
}
.header-links__toggle {
    display: none;
}

.header-links__toggle[aria-expanded='false'] + .header-links__wrapper {
    display: block;
}

.header-links__wrapper {
    position: static;
    background-color: transparent;
    display: block;
    border-top: none;
}

.header-google-translate {
    position: absolute;
    right: pxToRem(40);
    top: pxToRem(32);
    display: inline-block; 
    width: auto;
    text-align: right;
    padding-top: 0;
    padding-bottom: 0;

    .goog-te-combo {
        border: 0.1rem solid #ECECEC;
        padding: 0.2rem 0.2rem !important;
    }
}