/* Module: S */
.quick-search__toggle {
    width: pxToRem(22);
    height: pxToRem(22);
    border: none;
    padding: 0;
    position: absolute;
    right: pxToRem(54);
    top: pxToRem(26);
    background-color: transparent;
    z-index: 1;
}
.quick-search__toggle[aria-expanded='false'] + .quick-search__form {
    display: none;
}

.quick-search__toggle .icon-toggle-cross {
    display: block;
}

.quick-search__toggle[aria-expanded='false'] .icon-toggle-cross {
    display: none;
}

.quick-search__toggle .icon-search-mobile {
    display: none;
}

.quick-search__toggle[aria-expanded='false'] .icon-search-mobile {
    display: block;
}

.quick-search__form {
    background: $white;
    position: absolute;
    top: 70px;
    width: 100%;
    left: 0;
    border-top: pxToRem(1) solid #888;
    padding: pxToRem(96) pxToRem(15);
    text-align: center;
    z-index: 3;
}

.quick-search__input[type="text"] {
    font-size: pxToRem(22);
    line-height: 1.64;
    width: 100%;
    text-align: left;
    border-bottom: pxToRem(2) solid $black;
    border-top: none;
    border-left: none;
    border-right: none;

    &:focus {
        border-bottom-width: pxToRem(3);
    }

    &::-ms-clear,
    &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }
}

.quick-search__input::placeholder {
    color: #757575;
}

.quick-search__submit {
    margin: pxToRem(32) auto 0;
}

.quick-search__submit .icon-quick-search {
    display: none;
}