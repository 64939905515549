/* Module: S */
.p-perform-menu {

  &__list {
    width: pxToRem(1140 + 80);
    margin-left: auto;
    margin-right: auto;
    padding-left: pxToRem(40);
    padding-right: pxToRem(40);
    border-bottom: pxToRem(5) solid $black;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  &__item {
    padding: pxToRem(22) 0;
    display: inline-block;
    text-align: center;
    padding-left: none;
    border: none;
    
    &:first-child { 
      border:none;
    }
    &:nth-child(2), &:nth-child(4) {
      .p-perform-menu__link {
        border: none;
      }
    }
  }

  &__link {
    width: pxToRem(200);
    border-left: pxToRem(1) solid $warm-grey;
    border-right: pxToRem(1) solid $warm-grey;
    font-size: pxToRem(20);
  }
}