/* Module: S */
.home-slider__slides {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.home-slider__slide-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
}

.home-slider__slide--video {
  position: relative;

  &::before {
    @include sq-transition(0.2s all);
    content: "";
    display: block;
    position: absolute;
    width: pxToRem(60);
    height: pxToRem(60);
    background: url(../mysource_files/icon-play.svg) no-repeat center;
    background-size: cover;
    top: 30%;
    left: 50%;
    margin-top: pxToRem(-30);
    margin-left: pxToRem(-50);
    z-index: 1;
    pointer-events: none;
  }

  &:hover {
    &:before {
      @include sq-transition(0.2s all);
      width: pxToRem(65);
      height: pxToRem(65);
      margin-top: pxToRem(-32);
      margin-left: pxToRem(-53);
    }
  }
}

.home-slide__description {
  display: none;
}

.home-slide__image {
  height: pxToRem(260);
  object-fit: cover;
  width: 100%;
}

.home-slide__meta {
  background-color: $black;
  color: $white;
  height: pxToRem(175);
  overflow-x: hidden;
  padding: pxToRem(15) pxToRem($padding-global);
}

.home-slide__date {
  font-size: pxToRem(12);
  text-transform: uppercase;
}

.home-slide__link {
  font-size: pxToRem(26);
  color: $white;
  text-decoration: none;
  display: block;
}

.home-slide__more .icon-arrow-right {
  width: pxToRem(20);
  height: pxToRem(13);
  fill: $marigold;
}

.lity {
  background:rgba(0, 0, 0, 0.2) !important;
}

.perform-promo__container {
    background-image: url('{{file_dest}}/landing-art-bg-temp.png');
    background-position: center center;
    background-repeat: repeat;
    padding: pxToRem(15) 0;

    .perform-promo {
      border-top:0;
      margin-top: pxToRem(80);
    }
}

.landing-promo__video { 
  position: relative;

  &-link {
    display: inline-block;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: pxToRem(68);
      height: pxToRem(69);
      background-image: url({{file_dest}}/icon-play.png);
      background-repeat: no-repeat;
      background-position: 0 0;
      position: absolute;
      top: calc(26% - 25px);
      left: calc(50% - 34px) !important;
      z-index: 3;
    }
  }
  
  &-link:hover {
          &::before {
        background-image: url({{file_dest}}/icon-play-hover.png);
      }
  }
}

.perform-promo__border {
  display: block;
  height: pxToRem(3);
  max-width: pxToRem(1140);
  background: $black;
  margin: 0 auto;
}