/* Module: S */
.blog {
    color: $black-two;
    text-align: center;
}

.blog__list {
    margin: pxToRem(-10);
    padding: 0; 
    list-style-type: none;
    text-align: left;
}

.blog__item {
    padding: pxToRem(10);
}

.blog__wrapper {
    position: relative;
    border-bottom: pxToRem(3) solid $black;
    background-color: $white;
}

.blog__image {
	img {
		width: 100%;
		display: block;
		border: none;
	}
}

.blog__date {
    position: absolute;
    background-color: $marigold;
    border-bottom: pxToRem(5) solid $black-two;
    width: pxToRem(68);
    height: pxToRem(70);
    text-align: center;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    line-height: 1;
    display: flex;
    top: 0;
    left: 0;
}

.blog__day {
    font-size: pxToRem(26);
    line-height: 1.15;
    font-weight: 600;
    display: block;
}

.blog__month {
    font-size: pxToRem(14);
    text-transform: uppercase;
    display: block;

}

.blog__topic {
	font-size: pxToRem(13);
	font-weight: 600;
	letter-spacing: 0.22px;
	color: $black-two;
	text-transform: uppercase;
	display: inline-block;
	background-color: $marigold;
	padding: pxToRem(7) pxToRem(10);
}

.blog__info {
	padding: pxToRem(20);
}

.blog__title {
    font-size: pxToRem(18);
    font-weight: 600;
    margin: 0;
}

.blog__link {
    text-decoration: none;
    color: $black-two;
    display: block;
}

.blog__desc {
    line-height: 1.27;
    font-size: pxToRem(15);
    color: $greyish-brown;
    margin: 0 0 pxToRem(10);
    display: none;
}

.blog__meta {
    display: block;
    padding-bottom: pxToRem(20);
}

.blog__more-button {
    display: inline-block;
    margin: pxToRem(27) auto;
    text-decoration: none;
    color: $black-four;

    &.disabled {
        background-color: $white-seven;
    }
}

.blog__more-button .icon-arrow-down {
    width: pxToRem(11);
    height: pxToRem(10);
    transform: rotate(90deg);
    margin-left: pxToRem(5);
}

.blog__more {
    text-decoration: none;
    font-size: pxToRem(13);
    color: $black-two;
    display: block;
    padding: 0 pxToRem(15);

    &:hover {
        text-decoration: underline;
    }
}

.blog__more .icon-arrow-right {
    width: pxToRem(17);
    height: pxToRem(11);
    display: block;
    position: relative;
    margin-left: pxToRem(10);
    display: inline;
}

.blog__copy-link {
    display: none;
    text-decoration: none;
    font-size: pxToRem(13);
    color: $black-two;
    line-height: pxToRem(20);
    background-color: $white-five;
    padding: pxToRem(14) pxToRem(10) pxToRem(14) pxToRem(15); 

    &:hover {
        text-decoration: underline;
    }
}

.blog__copy-link .icon-link {
    width: pxToRem(17);
    height: pxToRem(15);
    margin-right: pxToRem(10);
    position: relative;
    top: pxToRem(3);
}

.blog__email-link {
    text-decoration: none;
    font-size: pxToRem(13);
    color: $black-two;
    display: none;
    line-height: pxToRem(20);
    background-color: $white-five;
    padding: pxToRem(14) pxToRem(15) pxToRem(14) pxToRem(10); 

    &:hover {
        text-decoration: underline;
    }
}

.blog__email-link .icon-mail {
    width: pxToRem(19);
    height: pxToRem(14);
    margin-right: pxToRem(10);
    position: relative;
    top: pxToRem(2);
}