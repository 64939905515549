/* Module: S */
.content-wrapper {
    justify-content: space-between;
    margin-top: pxToRem(50);
    flex-flow: row;
    max-width: pxToRem(1140 + 80);
    margin-left: auto;
    margin-right: auto;
    position: static;
    padding-top: 0;
}

.content__top-bar {
    display: flex;
    justify-content: space-between;
    margin-left: pxToRem(-40);
    margin-right: pxToRem(-40);
    padding-left: 0;
    padding-right: 0;

    .share-bar {
        padding-right: pxToRem(40);
    }

    .breadcrumbs {
        padding-left: pxToRem(40);
        padding-right: pxToRem(40);
    }
}

.content__top-bar .breadcrumbs {
    position: static;
}

.content {
    padding-left: pxToRem(40);
    padding-right: pxToRem(40);
    width: 100%;

    &__body {
        margin: 0;
        padding: 0 pxToRem(40) pxToRem(40) pxToRem(40);
        max-width: pxToRem(848);
    }

    p {
        &.intro {
            font-size: pxToRem(18);
            font-weight: 600;
            color: $black-two;
        }
    }

    h1 {
        font-size: pxToRem(40);
        font-weight: 500;
    }

    h2 {
        font-size: pxToRem(28);
        font-weight: 500;
    }

    h3 {
        font-size: pxToRem(20);
    }

    h4 {
        font-size: pxToRem(18);
    }

    h5 {
        font-size: pxToRem(14);
    }

    th {
        padding-left: pxToRem(40);
        font-weight: 600;
        font-size: pxToRem(14);
    }

    td {
        padding-left: pxToRem(40);
        padding-top: pxToRem(20);
        padding-bottom: pxToRem(20);
    }
}

.no-sidebar .content__body {
    max-width: 100%;
}



blockquote {
    font-size: pxToRem(18);
    overflow: hidden;
    display: block;
    padding-left: pxToRem(110);
    margin-bottom: pxToRem(30);
}
blockquote::before,
blockquote::after {
    top: pxToRem(40);
}
.blockquote__image {
    float: right;
    margin-left: pxToRem(30);
    width: 100%;
    max-width: pxToRem(317);
    position: static;
    margin-top: 0;
    margin-bottom: 0;
}


// Images

.content__image--left {
    float: left;
    margin-right: pxToRem(30);
}

.content__image--right {
    float: right;
    margin-left: pxToRem(30);
}

.content__image--center {
    float: none;
    margin: 0 auto;
}

.content__image--full img {
    width: 100%;
    height: auto;
}

p.content__image  {
    margin-top: pxToRem(5);
}

// Documents Section

.documents__item {
    padding-left: pxToRem(91);
    position: relative;
}

.documents__icon-wrapper {
    position: absolute;
    top: pxToRem(21);
    left: pxToRem(15);
}

.documents__description {
    margin-top: pxToRem(16);
    font-size: pxToRem(16);
}

.documents__download-link {
    padding-right: pxToRem(10);
}

.documents__download-link .icon-arrow-right {
    transition: .2s ease transform;
}

.documents__download-link:hover .icon-arrow-right {
    transform: translateX(10px);
}