/* Module: S */
.home-updates {
    text-align: center;
    padding-bottom: pxToRem(50);
    background-color: $white;

    &__wrapper {
        padding-top: pxToRem(10);
    }
}

.home-updates__header-link {
    text-decoration: none;
    color: $black-two;
}

.home-updates__header {
    font-size: pxToRem(15);
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
    margin-top: pxToRem(30);
    margin-bottom: pxToRem(30);

}

.home-updates__main-name {
    font-size: pxToRem(36);
    display: block;
    text-transform: capitalize;
}

.home-updates__list {
    margin: 0;
    padding: 0 pxToRem($padding-global);
    list-style-type: none;
    text-align: left;
}

.home-updates__item {
    border: pxToRem(1) solid $white-seven;
    margin-bottom: pxToRem(10);
}



.home-updates__item-link {
    text-decoration: none;
    position: relative;
    padding: pxToRem(26) pxToRem(22);
    display: block;
    height: 100%;
    height: 100%;
}

.home-updates__item-link::after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    height: pxToRem(4);
    left: pxToRem(-1);
    bottom: 0;
    background-color: $black-three;
    position: absolute;
}

.home-updates__title {
    display: inline-block;
    font-size: pxToRem(24);
    line-height: 1.21;
    font-weight: 500;
    border-bottom: pxToRem(2) solid $marigold;
    text-decoration: none;
    color: $black-two;
}

.home-updates__desc {
    font-size: pxToRem(15);
    line-height: 1.27;
    color: $greyish-brown;
    padding-bottom: pxToRem(30);
}

.home-updates__more {
    line-height: 1.47;
    font-size: pxToRem(14);
    position: absolute;
    bottom: pxToRem(22);
    left: pxToRem(22);
    width: calc(100% - 44px);
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: $greyish-brown;
}

.home-updates__more .icon-arrow-right {
    width: pxToRem(19);
    height: pxToRem(12);
}