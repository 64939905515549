/* Module: S */
.blog {
    padding-bottom: pxToRem(20);
    width: 100%;
}

.blog__list {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin: pxToRem(-26) pxToRem(-15);
}

.blog__item {
    padding: pxToRem(26) pxToRem(15);
}

.blog__wrapper {
    border-bottom-width: pxToRem(10);
	padding-bottom: pxToRem(48);
}

.blog__date {
    height: pxToRem(75);
}

.blog__day {
    font-size: pxToRem(28);
}

.blog__month {
    font-size: pxToRem(14);
}

.blog__title {
    font-size: pxToRem(26);
    font-weight: 500;
}

.blog__link {
    font-size: pxToRem(26);
}

.blog__info {
    padding: pxToRem(20) pxToRem(30) pxToRem(40) pxToRem(30);
}

.blog__desc {
    font-size: pxToRem(16);
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: pxToRem(7);
    display: block;
}

.blog__meta {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0;
}

.blog__more {
    position: absolute;
    left: 0;
    font-size: pxToRem(14);
    line-height: pxToRem(20);
    font-weight: 600;
    padding-top: pxToRem(14);
    padding-bottom: pxToRem(14);
}

.blog__copy-link {
    font-size: pxToRem(14);
    font-weight: 600;
    display: block;
}

.blog__email-link {
    font-size: pxToRem(14);
    font-weight: 600;
    display: block;
}

.blog__more-button {
	margin-top: pxToRem(35);
}