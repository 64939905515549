/* Module: S */
.container {
    width: $container-sm;
}

.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-offset-0,
.col-sm-offset-1,
.col-sm-offset-2,
.col-sm-offset-3,
.col-sm-offset-4,
.col-sm-offset-5,
.col-sm-offset-6,
.col-sm-offset-7,
.col-sm-offset-8,
.col-sm-offset-9,
.col-sm-offset-10,
.col-sm-offset-11,
.col-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-left: $half-gutter-width;
    padding-right: $half-gutter-width;
}

.col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-sm-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
}

.col-sm-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
}

.col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
}

.col-sm-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
}

.col-sm-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
}

.col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
}

.col-sm-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
}

.col-sm-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
}

.col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
}

.col-sm-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
}

.col-sm-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
}

.col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
}

.col-sm-offset-0 {
    margin-left: 0;
}

.col-sm-offset-1 {
    margin-left: 8.33333333%;
}

.col-sm-offset-2 {
    margin-left: 16.66666667%;
}

.col-sm-offset-3 {
    margin-left: 25%;
}

.col-sm-offset-4 {
    margin-left: 33.33333333%;
}

.col-sm-offset-5 {
    margin-left: 41.66666667%;
}

.col-sm-offset-6 {
    margin-left: 50%;
}

.col-sm-offset-7 {
    margin-left: 58.33333333%;
}

.col-sm-offset-8 {
    margin-left: 66.66666667%;
}

.col-sm-offset-9 {
    margin-left: 75%;
}

.col-sm-offset-10 {
    margin-left: 83.33333333%;
}

.col-sm-offset-11 {
    margin-left: 91.66666667%;
}

.start-sm {
    justify-content: flex-start;
    text-align: start;
}

.center-sm {
    justify-content: center;
    text-align: center;
}

.end-sm {
    justify-content: flex-end;
    text-align: end;
}

.top-sm {
    align-items: flex-start;
}

.middle-sm {
    align-items: center;
}

.bottom-sm {
    align-items: flex-end;
}

.around-sm {
    justify-content: space-around;
}

.between-sm {
    justify-content: space-between;
}

.first-sm {
    order: -1;
}

.last-sm {
    order: 1;
}