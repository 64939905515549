//Colours (generated by Zeplin Styleguide color palette)
$white: #ffffff;
$black: #000000;
$white-two: #faf8f8;
$white-three: #d8d8d8;
$marigold: #ffc107;
$black-two: #181818;
$black-three: #1e1e1e;
$greyish-brown: #494949;
$warm-grey: #858585;
$black-53: rgba(27, 27, 27, 0.53);
$warm-grey-two: #7d7d7d;
$black-four: #1b1b1b;
$warm-grey-three: #737373;
$dark-slate-blue: #212d65;
$lipstick-red: #b60a28;
$greyish: #a8a8a8;
$sunflower-yellow: #ffdf00;
$pinkish-grey: #c8c8c8;
$greyish-brown-two: #454545;
$white-four: #ebebeb;
$greyish-brown-three: #4a4a4a;
$yellow-orange: #fbbb00;
$dodger-blue: #518ef8;
$jade-green: #28b446;
$tomato: #f14336;
$greyish-two: #a5a5a5;
$brownish-grey: #626262;
$warm-grey-four: #707070;
$tomato-two: #d52b1e;
$medium-green: #34b233;
$scarlet: #d0021b;
$white-five: #ededed;
$marigold-30: rgba(255, 193, 7, 0.3);
$cerulean: #007ac9;
$white-six: #dddddd;
$warm-grey-five: #808080;
$warm-grey-six: #9b9b9b;
$warm-grey-seven: #979797;
$white-seven: #d7d7d7;
$pinkish-grey-two: #cbcbcb;
$marigold-50: rgba(255, 193, 7, 0.5);
$white-eight: #dedede;
$white-nine: #fbf9f9;
$frangipani: #ffda9f;
$ancient-gold: #997403;

// Colors outside of Zeplin generator

// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #222;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

$bp-xs: 30rem; // 480px
$bp-sm: 48rem; // 768px
$bp-md: 60rem; // 960px
$bp-lg: 75rem; // 1200px

$container-width-desktop: 1140;
$padding-global: 15;
$padding-wide: 30;
