/* Module: S */

 .landing-list--boxes .landing-list__item {
  .landing-list__new-h {
    //font-size: 1vw;
    font-size: pxToRem(16);
    top: inherit;
    //height: auto;
    //bottom:pxToRem(-64);
  }
  .landing-list__new-h:after {
    content:"";
  }
}

.landing-list__item.landing-list__new-item {
  margin: 0 pxToRem(8) pxToRem(75) pxToRem(7) !important;
}