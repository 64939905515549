/* Module: S */
.footer-col__header {
    font-size: pxToRem(18);
    line-height: 2;
    color: $black-two;
}

.footer-col__toggle {
    text-decoration: none;
    border-bottom: pxToRem(1) solid $black;
    width: 100%;
    display: flex;
    margin-top: pxToRem(10);
    justify-content: space-between;
    align-items: center;
    padding-right: pxToRem(10);
}

.footer-col__toggle .icon-toggle {
    width: pxToRem(14);
    height: pxToRem(9);
    transform: rotate(180deg);
}

.footer-col__header[aria-expanded='false'] + .footer-col__list {
    display: none;
}

.footer-col__header[aria-expanded='false'] + .icon-toggle {
    transform: rotate(0);
}

.footer-col__toggle {

}

.footer-col__list {
    font-size: pxToRem(15);
    line-height: 2.33;
    margin: 0;
    padding: pxToRem(14) 0 0 pxToRem(14);
    list-style-type: none;

}

.footer-col__link {
    color: $greyish-brown;
    text-decoration: none;
}

.footer-col__link:hover {
    text-decoration: underline;
}

