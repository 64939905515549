/* Module: S */
.landing-more {
}

.landing-more__heading {
}

.landing-more__text {
}

.btn {
}