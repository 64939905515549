/* Module: S */
.search-table {
    &__container {
        overflow-x: scroll;
    }
}
.webservice_results_wrapper{
    box-shadow: -10px 0 10px -10px #888 inset, 10px 0 10px -10px #888 inset;
    @media screen and (max-width: 64em) {
        overflow-x:scroll;
    }
}
.webservice_results {
    box-shadow:-20px 0 10px -10px #fff inset, 20px 0 10px -10px #fff inset;
    .tablesorter-header {
        cursor: pointer;
        min-width: 160px;
        padding-left: pxToRem(25);
        padding-right: pxToRem(25);
        position: relative;

        &:after, &:before {
            content: url(../mysource_files/arrow-bottom.svg);
            bottom: 0;
            display: block;
            height: pxToRem(10);
            margin-bottom: auto;
            margin-top: auto;
            position: absolute;
            right: pxToRem(8);
            top: 0;
        }

        &:after {
            transform: rotate(180deg);
        }

        &.tablesorter-headerAsc {
            &:after {
                height: pxToRem(20);
            }
            &:before {
                display: none;
            }
        }
        &.tablesorter-headerDesc {
            &:after {
                display: none;
            }
            &:before {
                height: pxToRem(20);
            }
        }
    }
}