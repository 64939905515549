/* Module: S */
sidebar {
    min-width: pxToRem(262);
    width: pxToRem(262);
    margin-right: pxToRem(40);
    margin-left: pxToRem(-10);
    order: 2;
    background-color: transparent;
}

.side-nav__l2-item {
	background-color: $white;
}

.side-nav {
    margin-top: 0;
    padding-bottom: 0;
}

.side-nav__toggle {
	display: none;
}

.side-nav__l2-list {
	display: block;
}