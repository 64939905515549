/* Module: S */
.p-perform-menu {
  background: $white;

  &__list {
    text-align: center;
    padding: 0;
  }

  &__item {
    display: block;
    text-align: left;
    list-style: none;
    padding: pxToRem(20) 0 pxToRem(20) pxToRem(20);
    padding-left: x;
    border-bottom: 1px solid $warm-grey;
    

    &:first-child {
      border-top: 1px solid $warm-grey;
    }
  }

  &__link {
    color: #737373;
    display: block;
    text-decoration: none;

    &:hover,
    &:visited,
    &:focus {
      font-weight: bold;
    }

    &.current {
      color: $black;
      font-weight: bold;
    }
  }
}