/* Module: S */
.content--search {
    background-color: $white-two;
    margin: pxToRem(-20) pxToRem(-20) pxToRem(-50) pxToRem(-20);
    padding: 0 pxToRem(20) pxToRem(30) pxToRem(20);
    display: flex;
    flex-flow: column;
}

.form--site-search {
    display: flex;
    flex-flow: column;
    width: 100%;
    @include placeholder-color($black-two);

    .content & {
      margin-bottom: 0;
    }

    .search {
        position: relative;
        color: $black-two;
        text-align: center;

        .twitter-typeahead {
            width: 100%;
        }

        input[type="text"] {
            background-color: $marigold;
            border-bottom: 5px solid $black-three;
            padding: pxToRem(19) pxToRem(60) pxToRem(19) pxToRem(20);
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            -webkit-appearance: none;
            padding: pxToRem(20);
            border: 0;
            background-color: transparent;
            border-radius: 0;

            svg {
                width: pxToRem(20);
                height: pxToRem(20);
            }
        }
    }
}

.search__header {
    font-size: pxToRem(36);
    text-align: center;
    font-weight: 500;
}

.search__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: left;

    .mb-display span {
        display: inline-block !important;
    }
}

.search__item {
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
    position: relative;
    border-bottom: pxToRem(3) solid $black;
    padding-top: pxToRem(21);
    padding-bottom: pxToRem(21);
    background-color: $white;
    margin-bottom: pxToRem(10);
}

.search__date {
    position: absolute;
    top: pxToRem(21);
    left: 0;
}

.search__day {
    font-size: pxToRem(26);
    line-height: 1.15;
    font-weight: 600;
}

.search__month {
    font-size: pxToRem(14);
    text-transform: uppercase;
    display: block;

}

.search__title {
    font-size: pxToRem(18);
    font-weight: 600;
    margin: 0;
}

.search__link {
    text-decoration: none;
    color: $black-two;
}

.search__type {
    margin-top: pxToRem(5);
    margin-bottom: pxToRem(10);
}

.search__desc {
    line-height: 1.27;
    font-size: pxToRem(15);
    color: $greyish-brown;
    margin: 0 0 pxToRem(10);
}

.search__meta {
    display: flex;
    justify-content: flex-end;
    position: relative;

    span {
        display: none;
    }

    a {
        text-decoration: none;
        font-size: pxToRem(13);
        line-height: pxToRem(30);
        font-weight: 600;
        color: $greyish-brown;
        display: block;
        min-width: pxToRem(30);
        text-align: right;
        margin-left: pxToRem(10);

        &.search__more {
            font-size: pxToRem(14);
            line-height: pxToRem(30);
            color: $black-two;
            font-weight: normal;
            text-align: left;
            margin-left: 0;
            margin-right: pxToRem(10);
            position: absolute;
            left: 0;
            top: 0;
        }

        &.active {
            span {
                display: inline;
            }
        }

        svg {
            position: relative;
            top: pxToRem(3);
        }
    }

    .icon-arrow-right {
        width: pxToRem(17);
        height: pxToRem(11);
        top: pxToRem(2);
    }

    .icon-link {
        width: pxToRem(17);
        height: pxToRem(16);
    }

    .icon-mail {
        width: pxToRem(19);
        height: pxToRem(14);
    }
}

.search__more-button {
    display: inline-block;
    margin: pxToRem(27) auto;
    text-decoration: none;
    color: $black-four;

    .icon-arrow-right {
        width: pxToRem(11);
        height: pxToRem(10);
        transform: rotate(90deg);
        margin-left: pxToRem(5);
    }

    &.disabled {
        background-color: $white-seven;
    }
}