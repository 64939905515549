/* Module: S */
.home-events {
    &__wrapper {
        width: pxToRem(1140);
        margin-left: auto;
        margin-right: auto;
        border-bottom: pxToRem(2) solid $pinkish-grey;
        padding-bottom: pxToRem(30);        
        padding-top: pxToRem(10);        
    }
}

.home-events__header {
    font-size: pxToRem(50);
}

.home-events__list {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
}

.home-events__item {
    width: 30%;
    border-bottom: none;
    padding-left: pxToRem(98) !important; 
}

.home-events__item .icon-arrow-right {
    display: block;
    opacity: 0;
    transform: translateX(-10px);
    transition: .2s ease all;
    width: pxToRem(20);
    height: pxToRem(13);
    float: right;
}

.home-events__item:hover .icon-arrow-right {
    opacity: 1;
    transform: translateX(0);
}

.home-events__date {
    background-color: $marigold;
    border-bottom: pxToRem(5) solid $black-two;
    width: pxToRem(68);
    height: pxToRem(70);
    text-align: center;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    line-height: 1;
    display: flex;
}

.home-events__day {
    font-size: pxToRem(28);
    display: block;
}

.home-events__month {
    font-size: pxToRem(14);
    display: block;
}

.home-events__title {
    font-size: pxToRem(20);
}

.home-events__desc {
    font-size: pxToRem(16);
    line-height: 1.5;
}

.home-events__location {
    font-size: pxToRem(13);
}

.home-events__location-label {
}

.btn {
}