/* Module: S */
.filters {
  background-color: $marigold;
  padding: pxToRem(20) pxToRem(20) pxToRem(30);
  border-bottom: pxToRem(4) solid $black-two;
  align-self: flex-start;
  order: -1;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 30px);
  margin-bottom: pxToRem(30);
  margin-top: pxToRem(-30);
  position: relative;
}

.filters--foldable {
  padding-bottom: 0;
}

.filters--foldable .filters__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.filters--foldable .filters__heading::after {
  content: "";
  display: inline-block;
  width: pxToRem(11);
  height: pxToRem(7);
  background-image: url("../mysource_files/arrow-bottom.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transform: rotate(0deg);
  transition: 0.2s ease all;
}

.filters--foldable .filters__heading--active::after {
  transform: rotate(-180deg);
  transition: 0.2s ease all;
}

.filters--foldable {

  .filters__form {
    display: none;
    padding-bottom: pxToRem(30);
  }

  .industry-select,
  .audience-select,
  .type-select,
  .topic-select {
    display: block;
  }

  .filter__container {
    display: none;
  }
}

.filters--foldable .filters__heading--active + .filters__form {
  display: block;
}

.filters--foldable .filters__heading--active + .filter__container {
  display: block;
}

.filters ul {
  margin: pxToRem(10) 0 0;
  padding: 0;
  list-style-type: none;
}

.filters li {
  padding-bottom: pxToRem(8);

  .resources-listing & {
    label {
      // color: #494949 !important;
      font-weight: 400 !important;
    }
  }
  a {
    color: $black-two;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &.active {
      font-weight: 600;
    }
  }
}

.filters {
  input[type="checkbox"] + label:not([class])::before {
    background-color: transparent;
  }
  input[type="checkbox"]:checked + label:not([class])::before {
    background-color: $white;
  }
}

.filters input[type="text"] {
  background-color: transparent;
  padding-left: 0;
  margin-bottom: pxToRem(8);
}

.filters select {
  margin-bottom: pxToRem(8);
}

.filters input[type="text"]::placeholder {
  color: $black-two;
}

.filters .btn {
  margin-top: pxToRem(30);
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.filters__heading {
  margin: 0;
  margin-bottom: pxToRem(18);
  font-size: pxToRem(18);
}

.filter__name {
  display: block;
  margin-top: pxToRem(15);
  margin-bottom: pxToRem(0);
  font-size: pxToRem(18);
  font-weight: 600;

  &--separated {
  	margin-top: pxToRem(30);
  }
}

.filter__datepicker {
  position: relative;

  &:after {
    content: url(../mysource_files/datepicker.svg);
    display: block;
    position: absolute;
    top: pxToRem(7);
    right: 0;
  }

  + .filter__datepicker {
    margin-top: pxToRem(20);
  }

  input[type="text"] {
    padding-left: pxToRem(60);
    padding-right: pxToRem(40);
    font-weight: 600;
    position: relative;
    z-index: 1;
  }

  label {
    position: absolute;
    top: pxToRem(8);
    left: 0;
  }
}

.filters select {
  background-color: transparent;
  padding-left: 0;
}

.content {
  .filter__name {
    font-size: pxToRem(18);
    font-weight: 600;
  }

  .filters {
    width: 100%;
    margin-top: 0;

    ul {
      &:last-child {
        padding-bottom: pxToRem(20);
      }
    }
  }
}

.perform {
  .filters {
    margin-top: pxToRem(60);
    margin-bottom: 0;
  }
}

.ui-datepicker {
  background-color: $white;
  padding: pxToRem(20);
  margin-left: pxToRem(-15);
  width: pxToRem(290);
  border:1px solid lightgrey;
  a {
    color: $black-two;
  }

  .ui-datepicker-prev,
  .ui-datepicker-next {
    width: pxToRem(30);
    height: pxToRem(30);
    top: 0;
    border: 1px solid $black-two;

    &:after {
        content: url(../mysource_files/arrow-datepicker.svg);
        width: pxToRem(15);
        line-height: pxToRem(30);
        display: block;
        position: absolute;
        top: 0;
        left: pxToRem(7);
    }

    &-hover {
      top: 0;
      background-color: $marigold;
    }
  }

  .ui-datepicker-prev {
    left: 0;

    &:after {
      @include sq-transform(rotate(180deg));
    }

    &-hover {
      left: 0;
    }
  }

  .ui-datepicker-next {
    right: 0;

    &-hover {
      right: 0;
    }
  }

  .ui-state-active {
    font-weight: 600;
  }

  .ui-state-default {
    &:hover {
      text-decoration: underline;
    }
  }
}
.filters__button-container {
  position: relative;
  text-align: center;
}
.filter__clear-btn {
  font-size: pxToRem(18);
  display: inline-block;
  line-height: 1rem;
  text-decoration: none;
  color: $black;
  margin-left: pxToRem(10);
}

.filters__select {
  position: relative;

  span.init {
    display: block;
    width: 100%;
    border-bottom: 0.0625rem solid #181818;
    background: url("../mysource_files/arrow-bottom.svg")
    calc(100% - 0.625rem) center no-repeat;
    padding-right: pxToRem(25);
    padding-left: pxToRem(10);
    margin-top: pxToRem(10);
    &::-ms-expand {
    // for IE 11
     display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }

  @include clearfix;
  // &-list {
  //   height: pxToRem(30);
  //   width: 100%;
  //   border-bottom: 0.0625rem solid #181818;
  //   background: url("../mysource_files/arrow-bottom.svg")
  //   calc(100% - 0.625rem) center no-repeat;
  //   padding-right: pxToRem(25);
  //   &::-ms-expand {
  //   // for IE 11
  //    display: none;
  //   }
  // }
  &-list {
    position: absolute;
    width: 100%;
    top:pxToRem(13);
    // left: pxToRem();
    border: 1px solid $white;
    border-radius: pxToRem(3);
    border-bottom:none;
    padding: 0;
    z-index: 3;
    padding-bottom: 0 !important;
    // display: none;
  }

  li {
    padding: pxToRem(10) pxToRem(10);
    z-index: 2;
    width: 100%;
    background: $white;
    border-bottom: 0.01rem solid #eaeaea;

    &:last-child {
      border-bottom: none;
    }

    &:not(.init):hover,
    &.selected:not(.init) {
      text-decoration: underline;
      cursor: pointer;
    }

    &.init {
      cursor: pointer;
    }
  }
}



