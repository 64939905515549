/* Module: S */
.footer-bottom-links {
    font-size: pxToRem(15);
    margin: 0;
    padding: pxToRem(20) pxToRem(15) pxToRem(5);
    list-style-type: none;
    line-height: 1.8;
}

.footer-bottom-links__link {
    color: $greyish-brown;
    text-decoration: none;
}

.footer-bottom__foot-note {
    padding: pxToRem(0) pxToRem(15) pxToRem(15);
    color: $greyish-brown;
    font-size: pxToRem(15);
}

.footer-logo {
    background-color: $black;
    display: block;
    padding: pxToRem(15);
}

.footer-logo .icon-safework-logo-white {
    width: pxToRem(156);
    height: pxToRem(42);
}

.footer-logo__wrapper{
    display: inline-block;
    width: 100%;
    background-color: #000;
    padding: pxToRem(15);
}

.footer-logo__link {
    height: auto;
    width: auto;
    display:inline-block;
}

.footer-logo__nsw {
    height: pxToRem(38.86);
}

.footer-logo__safework {
    width:pxToRem(128);
}