/* Module: S */
.quick-search__toggle {
    bottom: 0;
    right: pxToRem(40);
    top: auto;
    width: pxToRem(70);
    height: pxToRem(70);
    background-color: $marigold;
    z-index: 6;
}

.quick-search__toggle .svg-icon {
    width: pxToRem(20);
    height: pxToRem(20);
    margin-left: auto;
    margin-right: auto;
}

.quick-search__form {
    top: pxToRem(100);
    background: $marigold;
    height: pxToRem(70);
    border-top: none;
    width: 100vw;
    left: calc(50% - 50vw);
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: calc((100vw - 1140px) / 2);
    padding-right: calc((100vw - 1140px) / 2);

    @include placeholder-color($black-two);
}

.quick-search__input[type="text"] {
    order: 2;
    border: none;
    padding: pxToRem(17) pxToRem(70) pxToRem(17) pxToRem(12);
    background-color: transparent;
    margin: 0;
}

.quick-search__submit {
    margin-top: 0;
    order: 1;
    padding-left: pxToRem(30);
    padding-right: pxToRem(13);
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 1px;
        position: absolute;
        top: pxToRem(20);
        bottom: pxToRem(20);
        right: 0;
        background-color: rgba($black-two, 0.5);
    }
}

.quick-search__submit .icon-quick-search {
    display: block;
    width: pxToRem(20);
    height: pxToRem(20);
}

.quick-search__submit-text {
    display: none;

}