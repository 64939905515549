/* Module: S */
.consultations {
    background-image: url('{{file_dest}}/home-art-bg-temp.png');
    background-position: center center;
    background-repeat: repeat;
    padding: pxToRem(30) pxToRem(15);
}

.consultations__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.consultations__item {
    margin-bottom: pxToRem(10);
}

.consultations__image-wrapper {
    position: relative;
}

.consultations__category {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $marigold;
    width: pxToRem(58);
    height: pxToRem(58);
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-injury-icon,
    .icon-wall-icon,
    .icon-car-icon {
        width: pxToRem(32);
        height: pxToRem(52);
    }
}



.consultations__image {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    height: pxToRem(140);
    object-fit: cover;
}

.consultations__meta {
    background-color: $white;
    padding: pxToRem(10) pxToRem(22);
    border-bottom: pxToRem(4) solid $black-three;
}

.consultations__name {
    display: inline-block;
    font-size: pxToRem(20);
    line-height: 1.5;
    font-weight: 500;
}

.consultations__link {
    color: $black-two;
    text-decoration: none;
    font-weight: 600;
}

.consultations__desc {
    font-size: pxToRem(16);
    line-height: 1.5;
    color: $greyish-brown
}

.consultations__more {
    line-height: 1.71;
    color: $black-two;
    font-size: pxToRem(14);
    text-decoration: none;
    display: flex;
    justify-content: space-between;
}

.consultations__more .icon-arrow-right {
    width: pxToRem(19);
    height: pxToRem(12);
    margin-top: pxToRem(6);
}

.consultations__status-wrapper {
    font-size: pxToRem(13);
    line-height: 1.08;
    color: $greyish-brown;
    text-align-last: left;
}

.consultations__status strong {
    font-weight: 600;
}

.button__container {
    margin: 0 auto;
    text-align: center;
    margin-top: pxToRem(50);
}