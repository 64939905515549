/* Module: S */
.footer-contact__list {
    font-size: pxToRem(15);
}

.footer-contact__item {
    border: pxToRem(1) solid $warm-grey-five;
    padding-left: pxToRem(22);
    padding-right: pxToRem(22);
    height: pxToRem(55);
    line-height: pxToRem(55);
    display: block;
    margin-bottom: pxToRem(6);
    text-decoration: none;
    color: $black;
    white-space: nowrap;
}

.footer-contact__item--phone {
    font-size: pxToRem(18);
}

.footer-contact__item .svg-icon {
    position: relative;
}

.footer-contact__item-text {
    padding-left: pxToRem(11);
}

.icon-contact-phone {
    width: pxToRem(21);
    height: pxToRem(21);
    top: 3px;
}

.icon-contact-mail {
    width: pxToRem(22);
    height: pxToRem(16);
    top: 3px;
}

.icon-contact-feedback {
    width: pxToRem(21);
    height: pxToRem(21);
    top: 6px;
}

.mobile-share-bar {
    display: block;
}
.desk-share-bar {
    display: none;
}