/* Module: S */
.home-today-safeworks__wrapper {
    background-color: $white-two;
}

.home-today-safeworks {
    padding: pxToRem(30) pxToRem($padding-global) pxToRem(32);
}

.home-ts__header {
    font-size: pxToRem(15);
    font-weight: normal;
    text-align: center;
    line-height: 1;
}

.home-ts__header-name {
    font-size: pxToRem(36);
    display: block;
}

.home-ts__links-bar {
}

.home-ts__link {
    background-color: $white;
    height: pxToRem(55);
    line-height: pxToRem(55);
    padding-left: pxToRem(16);
    position: relative;
    display: block;
    margin-bottom: pxToRem(3);
    color: $black-two;
    text-decoration: none;
    font-weight: 600;
    font-size: pxToRem(18);
}

.home-ts__link .icon-arrow-right {
    width: pxToRem(19);
    height: pxToRem(12);
    position: absolute;
    right: pxToRem(17);
    top: calc(50% - 6px);
}

.home-ts__sharebox .share-bar {
    text-align: center;
}