/* Module: S */
.risk-predictor {

	&__wrapper {
		padding: 0 pxToRem(20);
	}

	h3 {
		font-size: pxToRem(26);
		line-height: 1.31;
		color: $black;
	}

	&__selector {
		position: relative;
		padding-bottom: pxToRem(100);
	}

	&__container {
		padding-bottom: pxToRem(100);

		h2.risk-predictor__summary-title {
			font-size: pxToRem(18);
			line-height: 1.44;
		}

		h3.title,
		h3.risk-predictor__info {
			font-size: pxToRem(30);
			line-height: 1.13;
		}
	}

	&__alert {
		font-size: pxToRem(20);
		line-height: 1.5;
		padding: pxToRem(30) 0 pxToRem(10) pxToRem(110);
		background-size: pxToRem(50);
	}


	&__select {
		width: 50%;
		border-left: pxToRem(2) solid $black;

		&:first-child {
			border-left: none;
		}

		// &:after {
		// 	width: pxToRem(100);
		// 	height: pxToRem(100);
		// }
		&-arrow {
						width: pxToRem(100);
			height: pxToRem(100);
		}


		select {
	        font-size: pxToRem(18);
			padding: pxToRem(38) pxToRem(60) pxToRem(37) pxToRem(40);
		}
	}

	&__submit {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: pxToRem(-106);
		width: pxToRem(212);
		padding: pxToRem(19) 0;

		input[type="submit"] {
			background-color: $marigold;
			color: $black;
		}
	}

	&__item {
		a {
			padding: pxToRem(30) pxToRem(40);
		}
	}

	&__info {
		padding-top: pxToRem(10);
		padding-bottom: pxToRem(35);
		font-size: pxToRem(20);
		line-height: 1.1;
		font-weight: normal;
	}

	&__period {
		padding: 0 pxToRem(5);
	}

	&__data {
		font-size: pxToRem(50);
		line-height: 1.2;
	}

	&__percentage {
		font-size: pxToRem(18);
		padding: pxToRem(28) pxToRem(10) pxToRem(7) 0;
	}

	&__label {
		padding: pxToRem(32) 0 pxToRem(6) 0;
	}
}