/* Module: S */
.home-personas {
    background-color: $white-two;
    padding: pxToRem(22) pxToRem(15) pxToRem(56);
}

.home-personas__persona {
    background-color: $white;
    color: $black-two;
    display: block;
    margin-bottom: pxToRem(7);
    padding: pxToRem(29) pxToRem(18) pxToRem(19);
    text-align: center;
    text-decoration: none;
}

.home-personas__iam {
    display: block;
    font-size: pxToRem(18);
}

.home-personas__role {
    display: block;
    font-size: pxToRem(34);
    line-height: 1;
}

.home-personas__or {
    color: $greyish-brown;
    display: block;
    font-size: pxToRem(14);
}

.home-personas__persona .icon-persona {
    display: block;
    margin-bottom: pxToRem(8);
    margin-left: auto;
    margin-right: auto;
}

.home-personas__persona--employer .icon-home-employer {
    height: pxToRem(43);
    width: pxToRem(60);
}

.home-personas__persona--worker .icon-home-worker {
    height: pxToRem(45);
    width: pxToRem(58);
}

.home-personas__more {
    display: block;
    text-align: right;
}

.home-personas__persona .icon-arrow-right {
    fill: $black-two;
    height: pxToRem(12);
    width: pxToRem(19);
}
