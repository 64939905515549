/* Module: S */
.footer-columns {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.footer-col {
    width: 22%;
}

.footer-col__header {
    font-size: pxToRem(16);
    font-weight: 500;
    margin-top: pxToRem(16);
    margin-bottom: pxToRem(16);
    display: block;
    width: 100%;
    
}

.footer-col__toggle {
    border-bottom: none;
    padding-right: 0;
}

.footer-col__toggle .icon-toggle {
    display: none;
}

.footer-col__header[aria-expanded='false'] + .footer-col__list {
    display: block;
}

.footer-col__list {
    padding-left: 0;
    padding-top: 0;
}