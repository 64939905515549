/* Module: S */
.home-investigations {
    text-align: center;
    color: $black-two;
    padding-top: pxToRem(30);
    background-color: $white;
}

.home-investigations__header-link {
    text-decoration: none;
    color: $black-two;
}

.home-investigations__header {
    font-size: pxToRem(15);
    margin-bottom: pxToRem(23);
    text-transform: uppercase;
    font-weight: 500;
}

.home-investigations__main-name {
    display: block;
    font-size: pxToRem(36);
    line-height: 1;
    text-transform: capitalize;
}

.home-investigatons__list {
    text-align: left;
    margin: 0;
    padding: 0 pxToRem($padding-global);
    list-style-type: none;
}

.home-investigations__item {
    background-color: $marigold;
    border-bottom: pxToRem(3) solid $black;
    margin-bottom: pxToRem(7);
    display: block;
}

.home-investigations__item-link {
    text-decoration: none;
    display: block;
    position: relative;
    min-height: pxToRem(130);
    padding: pxToRem(18) pxToRem(20);
    width: 100%;
    height: 100%;
}

.home-investigations__name {
    font-size: pxToRem(24);
    line-height: 1.17;
    margin: 0;
}

.home-investigations__summary {
    margin-bottom: 2.5em;
    color: $black-two;
}

.home-investigations__link {
    text-decoration: none;
    color: $black-two;
}

.home-investigations__more {
    position: absolute;
    bottom: pxToRem(18);
    left: pxToRem(20);
    font-size: pxToRem(13);
    display: flex;
    text-decoration: none;
    color: $black-two;
    width: calc(100% - 40px);
    justify-content: space-between;
}

.home-investigations__more .icon-arrow-right {
    width: pxToRem(19);
    height: pxToRem(12);
}

.home-investigations__all {
    display: inline-block;
    margin: pxToRem(18) auto;
    text-decoration: none;
    color: $black-two;
}