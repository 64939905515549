/* Module: S */
.landing-listing-simple {
    padding-bottom: pxToRem(20);
    width: 100%;

    &__wrapper {
        padding: pxToRem(50) 0 pxToRem(10) 0;
    }
}

.landing-listing-simple__list {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
}

.landing-listing-simple__item {
    border-bottom-width: pxToRem(10);
    margin-bottom: pxToRem(20);
    padding: pxToRem(30);
}


.landing-listing-simple__title {
    font-size: pxToRem(26);
    font-weight: 500;
    position: relative;
    display: inline-block;
    margin-bottom: pxToRem(10);
    padding-bottom: pxToRem(8);
}
.landing-listing-simple__title::after {
    content: '';
    display: block;
    width: 100%;
    height: pxToRem(3);
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $marigold;
}

.landing-listing-simple__link {
    font-size: pxToRem(28);
}

.landing-listing-simple__desc {
    font-size: pxToRem(18);
    line-height: 1.56;
    color: $greyish-brown;
    margin-top: pxToRem(10);
}
