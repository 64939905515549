/* Module: S */
.consultations-detail {

  .consultation {

    &__wrapper {
      padding: pxToRem(20) pxToRem(15) pxToRem(30);
      h2 {
        font-weight: 400;
        font-size: pxToRem(28);
      }
    }
    
    &__title  {
      font-weight: 400;
    }

    &__tabs {
      background: $white;

    }

    &__body {
      background-image: url('{{file_dest}}/home-art-bg-temp.png');
      background-position: center center;
      background-repeat: repeat;
    }
    
    &__tab-titles {
      display: none;
    }

    &__tab-title {
      list-style: none;
    }

    &__tab-btn {
      color: $black;
      font-weight: bold;
      text-decoration: none;
      font-size: pxToRem(20);
    }

    &__tab-contents {
      margin: 0;
      padding:0;
    }

    &__tab-content {
      list-style: none;
    }
    
    &__meta {
      color: #494949;
    }

    &__meta-detail {
      margin: 0;
    }
  }

  .accordion__item-content {
    ul {
      padding-left: pxToRem(20);
    }
  }

  .tabs__content--active {
    display: none;
  }

  .documents__item {
    border-bottom: none;
    padding: 0;
    display: inline-block;
    margin-bottom: pxToRem(10);
  }

  .documents__icon-wrapper {
    left: 0;
    top: pxToRem(10);
  }

  .documents__download-link {
    color: $black;
    margin-left: 0;
    text-decoration: underline;
  }

  .document__container {
    display: inline-block;
    margin-top: pxToRem(20);
  }

  form .sq-form-question-answer label {
    position: relative;
    padding-left: pxToRem(20);
    & > input {
      clip: auto;
      height: auto;
      width: auto;
      position: absolute;
      top:pxToRem(3);
      left: pxToRem(2);
      font-size: pxToRem(20);
    }
  }

  form .sq-form-question .error {
    color: $lipstick-red;
  }

  //ajax form loading style
  .spinner {
    margin: 100px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
  }

  .spinner > div {
    background-color: #333;
    height: 100%;
    width: 6px;
    display: inline-block;
    
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
    20% { -webkit-transform: scaleY(1.0) }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% { 
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
      }  20% { 
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
      }
    }

}
