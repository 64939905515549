/* Module: S */
.breadcrumbs--no-wrapper {
    padding: 0 pxToRem(40);
}


.breadcrumbs--consultations {
    max-width: pxToRem(1140 + 80);
    margin-left: auto;
    margin-right: auto;
    height: pxToRem(50);
    line-height: pxToRem(50);
    padding-left: pxToRem(40);
    padding-right: pxToRem(40);
}

.breadcrumbs {
    display: block;
    
    &__wrapper {
        display: block;
    	max-width: pxToRem(1140 + 80);
    	margin: pxToRem(20) auto pxToRem(-30) auto;

        .breadcrumbs {
            padding: 0 pxToRem(40);
        }
    }
}