/* Module: S */
.filters {
  margin-right: pxToRem(40);
  margin-left: 0;
  order: 2;
  min-width: pxToRem(262);
  width: pxToRem(262);
  margin-top: 0;
}

.filters--foldable .filters__heading {
  font-size: pxToRem(28);
}

.filters--foldable .filters__heading::after {
  display: none;
}

.filters--foldable .filters__form,
.filters--foldable .filter__container {
  display: block;
}

.filters .btn {
  display: inline-block;
}

.content {
  .filters {
  	width: pxToRem(262);
    margin-left: pxToRem(30);
    margin-right: 0;
  }
}

.perform {
  .filters {
    margin-left: pxToRem(30);
    margin-right: 0;
    margin-top: 0;
  }
}
.ui-datepicker {
  margin-left: pxToRem(-20);
  width: pxToRem(262);
}

.filter__clear-btn {
  right: pxToRem(77);
}

  .industry-select,
  .audience-select,
  .type-select,
  .topic-select {
    padding-bottom: 0 !important;
  }