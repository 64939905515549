$xs-min: $bp-xs;
$sm-min: $bp-sm;
$md-min: $bp-md;
$lg-min: $bp-lg;


$gutter-width: .938rem; // 16
$outer-margin-mobile: $gutter-width;
$outer-margin: 2 * $gutter-width;
$gutter-compensation: ($gutter-width / 2) * -1;
$half-gutter-width: $gutter-width / 2;

$container-sm: $sm-min + $gutter-width;
$container-md: $md-min + $gutter-width;
$container-lg: $lg-min + $gutter-width;