/* Module: S */
.accordion {
    border-bottom: pxToRem(2) solid $black;
    margin: 0 pxToRem(-20);

    .contact-box & {
        margin: 0 pxToRem(-20);
        margin-top: pxToRem(20);
    }
}

.accordion__item-name {
    position: relative;
    margin: 0;
    border-top: pxToRem(2) solid $black;

     .icon-arrow-right {
        width: pxToRem(12);
        height: pxToRem(19);
        fill: $black;
        position: absolute;
        top: calc(50% - 9px);
        right: pxToRem(15);
        transform: rotate(90deg);
    }

    &:hover {
        background-color: $marigold-30;
    }
}


.accordion__item--active {
    background-color: $marigold;

    &:hover {
        background-color: $marigold;
    } 

    + .accordion__item-content {
        display: block;
    }

    .icon-arrow-right {
        transform: rotate(270deg);
    }
}

.accordion__item-link {
    color: $black-two;
    text-decoration: none;
    display: block;
    font-size: pxToRem(15);
    line-height: pxToRem(20);
    padding: pxToRem(20) pxToRem(40) pxToRem(20) pxToRem(20);

    .contact-box & { 
        padding: pxToRem(20) pxToRem(40) pxToRem(20) pxToRem(20);
    }
}

.accordion__item-content {
    display: none;
    line-height: 1.5;
    font-size: pxToRem(16);
    color: $greyish-brown;
    padding: pxToRem(18) pxToRem(35) pxToRem(21) pxToRem(20);

    .contact-box & {
        color: $black;
        padding: pxToRem(5) pxToRem(25) pxToRem(21) pxToRem(20);
    }
}