/* Module: S */
.footer-logo__wrapper {
    width: auto;
}

.footer-bottom {
    width: pxToRem(1140);
    margin-top: pxToRem(70);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
}

.footer-logo {
    order: 1;
    padding-top: pxToRem(14);
    padding-bottom: pxToRem(14);
    display: inline-block;
}

.footer-bottom-links {
    order: 2;
    font-size: pxToRem(12);
    padding: 0 pxToRem(10);
    line-height: normal;
    display: flex;
    align-items: center;
}

.footer-bottom-links__item {
    display: inline-block;
    padding-left: pxToRem(14);
    padding-right: pxToRem(14);
    font-weight: 600;
}

.footer-bottom-links__link:hover {
    text-decoration: underline;
}

.footer-bottom__foot-note {
    order: 3;
    font-size: pxToRem(12);
    padding: 0 0 0 pxToRem(10);
    margin: 0;
    display: flex;
    align-items: center;
}