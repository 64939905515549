/* Module: S */
.container {
    width: $container-lg;
}

.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-offset-0,
.col-lg-offset-1,
.col-lg-offset-2,
.col-lg-offset-3,
.col-lg-offset-4,
.col-lg-offset-5,
.col-lg-offset-6,
.col-lg-offset-7,
.col-lg-offset-8,
.col-lg-offset-9,
.col-lg-offset-10,
.col-lg-offset-11,
.col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-left: $half-gutter-width;
    padding-right: $half-gutter-width;
}

.col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

.col-lg-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
}

.col-lg-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
}

.col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
}

.col-lg-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
}

.col-lg-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
}

.col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
}

.col-lg-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
}

.col-lg-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
}

.col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
}

.col-lg-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
}

.col-lg-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
}

.col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
}

.col-lg-offset-0 {
    margin-left: 0;
}

.col-lg-offset-1 {
    margin-left: 8.33333333%;
}

.col-lg-offset-2 {
    margin-left: 16.66666667%;
}

.col-lg-offset-3 {
    margin-left: 25%;
}

.col-lg-offset-4 {
    margin-left: 33.33333333%;
}

.col-lg-offset-5 {
    margin-left: 41.66666667%;
}

.col-lg-offset-6 {
    margin-left: 50%;
}

.col-lg-offset-7 {
    margin-left: 58.33333333%;
}

.col-lg-offset-8 {
    margin-left: 66.66666667%;
}

.col-lg-offset-9 {
    margin-left: 75%;
}

.col-lg-offset-10 {
    margin-left: 83.33333333%;
}

.col-lg-offset-11 {
    margin-left: 91.66666667%;
}

.start-lg {
    justify-content: flex-start;
    text-align: start;
}

.center-lg {
    justify-content: center;
    text-align: center;
}

.end-lg {
    justify-content: flex-end;
    text-align: end;
}

.top-lg {
    align-items: flex-start;
}

.middle-lg {
    align-items: center;
}

.bottom-lg {
    align-items: flex-end;
}

.around-lg {
    justify-content: space-around;
}

.between-lg {
    justify-content: space-between;
}

.first-lg {
    order: -1;
}

.last-lg {
    order: 1;
}