/* Module: S */
.home-links {
}

.home-links__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: pxToRem(18);
  display: flex;
  flex-direction: column;
}

.home-links__item {
  background-color: $marigold;
  margin-bottom: pxToRem(1);
  padding-left: pxToRem($padding-global);
  padding-right: pxToRem(90);
  position: relative;
  //   height: pxToRem(62);
}

.home-links__item:last-of-type {
  margin-bottom: 0;
}

.home-links__item::after {
  content: "";
  display: block;
  width: pxToRem(1);
  height: pxToRem(44);
  transform: rotate(24deg);
  position: absolute;
  background-color: $white;
  right: pxToRem(74);
  top: pxToRem(9);
}

.home-links__link {
  color: $black-two;
  line-height: pxToRem(22);
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  padding-top: pxToRem(20);
  padding-bottom: pxToRem(20);
}

.home-links__item .icon-arrow-right {
  width: pxToRem(19);
  height: pxToRem(12);
  position: absolute;
  //   top: calc(50% - 6px);
  top: pxToRem(26);
  right: pxToRem(32);
}
