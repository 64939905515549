/* Module: S */
.btn {
  display: inline-block;

  &.btn-small {
    padding: pxToRem(6) pxToRem(16);
  }

  &.btn-large {
    padding: pxToRem(18) pxToRem(60);
  }

  a {
    text-decoration: none;
    color: $black;
  }
}

.btn,
form input[type="submit"],
.sq-form-control input[type="submit"] {
  background-color: $marigold;
  font-size: pxToRem(15);
  font-weight: 600;
  padding: pxToRem(12) pxToRem(40);
  border-radius: pxToRem(23);
  border: none;
  cursor: pointer;
}

a.btn {
  color: $black;
  text-decoration: none;
}

.btn--arrow-down::after {
  content: "";
  display: inline-block;
  width: pxToRem(10);
  height: pxToRem(11);
  background-image: url("{{file_dest}}/arrow-down.svg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: pxToRem(8);
  position: relative;
  top: pxToRem(1);
}

.btn.btn--light {
  background-color: $white;
  color: $black;
}

.btn.btn--light:hover {
  background-color: $white-three;
}

.btn:hover,
.sq-form-control input[type="submit"]:hover {
  background-color: $marigold-50;
}
