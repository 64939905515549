/* Module: S */
.icon {
  fill: currentColor;
  height: 1em;
  overflow: hidden;
  vertical-align: -.15em;
  width: 1em;
}

.icon-small-extra {
  font-size: 12px;
  font-size: 0.75rem;
}

.icon-small {
  font-size: 16px;
  font-size: 1rem;
}

.icon-small-large {
  font-size: 25px;
  font-size: 1.5625rem;
}

.icon-medium {
  font-size: 30px;
  font-size: 1.875rem;
}

.icon-large {
  font-size: 50px;
  font-size: 3.125rem;
}

.icon-large-extra {
  font-size: 80px;
  font-size: 5rem;
}

.icon-large-extra-2 {
  font-size: 99.2px;
  font-size: 6.2rem;
}

.icon-black {
  color: #000;
}

.icon-white {
  color: #fff;
}

.icon-green {
  color: #467082;
}

.icon-external {
  margin-left: 5px;
  position: relative;
  top: -1px;
}

.icon-plus {
  height: 26px;
  position: absolute;
  right: 0;
  top: 0;
  width: 26px;
}

@media screen and (min-width: 48.125em) {
  .icon-plus {
    display: none;
  }
}

.icon-plus div {
  background-color: #fff;
  position: absolute;
}

.icon-plus-h {
  height: 6px;
  top: 50%;
  margin-top: -3px;
  width: 26px;
}

.icon-plus-v {
  height: 26px;
  left: 50%;
  margin-left: -3px;
  width: 6px;
}
