/* Module: S */
.section {
	h1 {
		font-size: pxToRem(54);
		line-height: 1.11;
		margin-bottom: pxToRem(-15);
	}

	h2 {
		font-size: pxToRem(40);
		margin-bottom: 0;
	}

	&__links {
		a {
			clear: none;
			margin-left: pxToRem(24);

			&:first-child {
				margin-left: 0;
			}
		}
	}

	&__wrapper {
		padding: pxToRem(90) pxToRem(30);
	}

	&-banner {
		&:before {
			background-color: rgba($black, 0.3);
		}

		h1,
		h2 {
			color: $white;
		}

		p {
			color: $white;
			font-size: pxToRem(18);
			font-weight: 500;
			line-height: 1.44;
		}

		.section {
			&__wrapper {
				padding-top: pxToRem(120);
				padding-bottom: pxToRem(120);
			}
		}
	}

	&-disclaimer {
		h2 {
			font-size: pxToRem(16);
		}
	}
}