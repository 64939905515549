/* Module: S */
.home-search {
    margin-top: 0;
    position: relative;
    z-index: 2;
    width: 100%;
    padding-left: pxToRem($padding-global);
    padding-right: pxToRem($padding-global);

    .content & {
        padding: 0;
    }
}

.home {
    .home-search {
        margin-top: pxToRem(-38);
    }
}

.landing-search__wrapper .home-search {
    margin-top: pxToRem(20);
}

.home-search__form {
    background-color: $marigold;
    padding: pxToRem(15) pxToRem(22) pxToRem(20);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
}

.home-search__form::after {
    content: '';
    display: block;
    width: 100%;
    height: pxToRem(1);
    background-color: $black;
}

.home-search__label {
    font-size: pxToRem(18);
    font-weight: 600;
    color: $black-two;
    width: 100%;
    padding-bottom: pxToRem(5);
}

.home-search__input {
    background-color: transparent;
    border: none;
    padding: pxToRem(6) 0;
    width: calc(100% - 20px);
    font-size: pxToRem(15);
    outline: none;
}

.home-search__input::placeholder {
    color: $black-two;
}

.home-search__submit {
    width: pxToRem(16);
    height: pxToRem(16);
    background-color: transparent;
    border: none;
    padding: 0;
    margin-top: pxToRem(8);
    display: none;
}

input.home-search__input::-ms-clear{
    display: none;
}

input.js-typeahead-query-resources::-ms-clear{
    display: none;
}