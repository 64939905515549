/* Module: S */
.risk-predictor {

	&__wrapper {
		max-width: pxToRem(1168);
		margin: 0 auto;
		padding: 0;
		// padding: 0 pxToRem(20);
		// padding: pxToRem(30) pxToRem(20) pxToRem(30);

	}

	&__selector {
		@extend %clearfix;
	}

	&__select {
		position: relative;
		background-color: $white;
		border-bottom: pxToRem(5) solid $black;
		float: left;
		width: 100%;

		// &:after {
		// 	content: '';
		// 	background-image: url(../mysource_files/arrow-down.svg);
		// 	background-position: center center;
		// 	background-repeat: no-repeat;
		// 	position: absolute;
		// 	top: 0;
		// 	right: 0;
		// 	width: pxToRem(55);
		// 	height: pxToRem(55);
		// }

		&-arrow {
			content: '';
			background-image: url(../mysource_files/arrow-down.svg);
			background-position: center center;
			background-repeat: no-repeat;
			position: absolute;
			top: 0;
			right: 0;
			width: pxToRem(55);
			height: pxToRem(55);
			&:hover {
				cursor: pointer;
			}
		}
		
		select {
	        font-size: pxToRem(14);
			color: $black-two;
			background-color: transparent;
			background-image: none;
			padding: pxToRem(18) pxToRem(54) pxToRem(18) pxToRem(22);
			width: 100%;
			border: none;
			border-radius: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			position: relative;
			z-index: 1;

			&::-ms-expand {
			    display: none; /* remove default arrow in IE 10 and 11 */
			}
		}
	}

	&__submit {
		text-align: center;
		padding-top: pxToRem(20);
		float: left;
		width: 100%;

		input[type="submit"] {
			background-color: $marigold;
			font-size: pxToRem(15);
			font-weight: 600;
			padding: pxToRem(12) pxToRem(40);
			border-radius: pxToRem(23);
			border: none;
			cursor: pointer;

			&:hover,
			&:focus {
				background-color: $frangipani;
			}
		}
	}

	&__container {
		display: none;
		padding-bottom: pxToRem(60);

		h2.risk-predictor__summary-title {
			font-size: pxToRem(14);
			line-height: 1.86;
			font-weight: 300;
			color: $black;
			text-transform: none;
			margin: 0;
			padding: pxToRem(30) 0;

			span {
				font-weight: bold;
			}
		}

		h3.title,
		h3.risk-predictor__info {
			font-size: pxToRem(18);
			font-weight: bold;
			line-height: 1.44;
			color: $black;
			margin: 0;
			padding-top: pxToRem(5);
		}

		ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
			@extend %clearfix;
		}

		li {
			padding: pxToRem(12) 0;
			margin: 0;
			display: block;
			border-bottom: pxToRem(2) solid $warm-grey;
			float: left;
			width: 100%;

			&:last-child {
				border-bottom: none;
			}
		}
	}

	&__alert,
	&__tip,
	&__infos {
		font-size: pxToRem(14);
		font-weight: 500;
		line-height: 1.44;
		padding: pxToRem(30) 0 pxToRem(10) pxToRem(56);
		background-size: pxToRem(38) pxToRem(38);
		background-position: center left;
		background-repeat: no-repeat;
	}

	&__alert {
		background-image: url(../mysource_files/alert.svg);
	}

	&__tip {
		background-image: url(../mysource_files/Tip.svg);
	}

	&__infos {
		background-image: url(../mysource_files/Info.svg);
	}

	&__item {
		background-color: $white;
		border-bottom: pxToRem(5) solid $black;

		a {
			color: $black;
			border: none;
			display: block;
			padding: pxToRem(24);
			text-decoration: none;
		}
	}

	&__info {
		font-size: pxToRem(14);
		line-height: 1.57;
		text-align: left;
		color: $brownish-grey;
		font-weight: 300;
		padding: pxToRem(5) 0 pxToRem(15) 0;
	}

	&__period {
		color: $black;
		text-transform: uppercase;
		background-color: $marigold;
		font-size: pxToRem(12);
		font-weight: 600;
		line-height: 1.5;
		display: inline-block;
		padding: 0 pxToRem(5);
	}

	&__data {
		color: $greyish-brown;
		font-size: pxToRem(22);
		font-weight: bold;
		line-height: 1.55;
		float: left;
		padding-right: pxToRem(10);
	}

	&__percentage {
		color: $black-two;
		font-size: pxToRem(16);
		font-weight: bold;
		line-height: 1.4;
		padding: pxToRem(8) pxToRem(10) pxToRem(4) 0;
		float: left;
	}

	&__label {
		color: $black-two;
		font-size: pxToRem(14);
		font-weight: 500;
		line-height: 1.6;
		padding: pxToRem(8) 0 pxToRem(4) 0;
		float: left;
	}

	&__link {
		display: inline-block;
		overflow: hidden;

		&-wrapper {
			color: $black-two;
			font-size: pxToRem(14);
			font-weight: 600;
			line-height: 1.4;
			padding-right: pxToRem(50);
			position: relative;
			left: 0;
		}

		& .icon-arrow-right {
			display: inline;
			width: pxToRem(17);
			height: pxToRem(11);
			position: relative;
		}
	}

	span.init {
		margin-top:0;
		border-bottom:0;
		display: block;
		padding: pxToRem(18) pxToRem(54) pxToRem(18) pxToRem(22);
		font-size: pxToRem(14);
		background:none;
		&:hover {
			cursor: pointer;
		}
	}

	.filters__select-list {
		position: absolute;
		top: pxToRem(45);
		left: 0;
		border: 1px solid $white;
		border-radius: pxToRem(5);
		@include box-shadow(1px, 1px, 3px 1px, #ccc);
	}

	.filters__select-item {
		list-style: none;
    padding: pxToRem(16) pxToRem(16);
    background:  $white;
    border-bottom: 0.01rem solid #eaeaea;
		
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

	.js-risk-predictor-related {
		.section__wrapper {
			.section__links a,
			p {
				font-size: pxToRem(18);
			}
			
		}
	}