/* Module: S */
.sidebar {
    background-color: $white;
    order: -1;
    width: 100%;
}

.side-nav__toggle {
    button {
        position: relative;
        font-size: pxToRem(18);
        line-height: pxToRem(23);
        font-weight: 600;
        display: block;
        background-color: $marigold;
        color: $black;
        padding: pxToRem(20) pxToRem(40) pxToRem(20) pxToRem(20);
        border: none;
        -webkit-appearance: none;
        width: 100%;
        text-align: left;
        position: relative;
        border-bottom: pxToRem(4) solid $black-two;
        outline: none;

        &:after {
            content: url(../mysource_files/arrow-down.svg);
            display: block;
            position: absolute;
            top: pxToRem(20);
            right: pxToRem(15);
        }

        &.active {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
}

.side-nav {
    margin-top: pxToRem(-30);
    padding-bottom: pxToRem(30);
    background-color: $white-two;
    .detailed-guide-body & {
        margin-top: 0;
    }
}

.side-nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.side-nav a {
    text-decoration: none;
}

.side-nav__l2-list {
    display: none;

    &.active {
        display: block;
    }
}

.side-nav__l2-item {
    margin-bottom: pxToRem(2);
}

.side-nav__l2-item--active .side-nav__l2-link,
.side-nav__l2-item--active .side-nav__l2-link:hover {
    background-color: $black-two;
    color: $white;
}

.side-nav__l2-link {
    position: relative;
    font-size: pxToRem(18);
    line-height: pxToRem(20);
    display: block;
    background-color: $marigold;
    color: $black;
    padding: pxToRem(20) pxToRem(40) pxToRem(20) pxToRem(20);
}

.side-nav__l2-link:hover {
    background-color: $marigold-50;
    color: $black;
}

.side-nav__l2-link .icon-arrow-bottom {
    width: pxToRem(9);
    height: pxToRem(6);
    position: absolute;
    top: calc(50% - 3px);
    right: pxToRem(15);
}

.side-nav__l2-item--active .icon-arrow-bottom {
    transform: rotate(180deg);
    fill: $marigold;
}

.side-nav__l3-list,
.side-nav__l4-list {
    display: none;
    font-size: pxToRem(16);
    background-color: $white;
}

.side-nav__l2-item--active .side-nav__l3-list,
.side-nav__l3-item--active .side-nav__l4-list {
    display: block;
}

ul.side-nav__l3-list {
    padding-left: pxToRem(20);
    padding-right: pxToRem(20);
}

.side-nav__l3-item {
    border-bottom: pxToRem(1) solid $pinkish-grey;
}

.side-nav__l3-link {
    padding: pxToRem(20) 0;
    font-size: pxToRem(16);
    line-height: pxToRem(20);
    display: block;
}

.side-nav__l3-item--active,
.side-nav__l3-item:hover {
    border-bottom-color: $black-two;
}


.side-nav__l3-item:last-of-type {
    border-bottom: none;
}

.side-nav__l3-link {
    color: $greyish-brown-three;
}

.side-nav__l3-item--active .side-nav__l3-link {
    color: $black-two;
    font-weight: 600;
}

ul.side-nav__l4-list {
    margin-left: pxToRem(20);
    margin-right: pxToRem(20);
    margin-bottom: pxToRem(20);
}

.side-nav__l4-link {
    color: $greyish-brown-three;
    font-size: pxToRem(16);
    line-height: pxToRem(20);
    display: block;
    padding: pxToRem(12) 0;
}

.side-nav__l4-item--active .side-nav__l4-link,
.side-nav__l4-link:hover {
    color: $black-two;
    text-decoration: underline;
}

.guide-print {
    background: $marigold;
    margin-bottom:  pxToRem(2);
    padding: pxToRem(15);
    color: $black;
    width: 100%;
    display: inline-block;
    h3 {

        margin-top:0;
        margin-bottom: 0;
    }

    .icon-black {
        margin-left: pxToRem(5);
        color: $black;
    }
}