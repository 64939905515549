/* Module: S */
.content--news-events {
    background-color: $white-two;    
}

.news-events {
    color: $black-two;
    text-align: center;
}

.news-events__header {
    font-size: pxToRem(36);
    text-align: center;
    font-weight: 500;
}

.news-events__list {
    margin: 0 pxToRem(-5);
    padding: 0; 
    list-style-type: none;
    text-align: left;
}

.news-events__item {
    padding: pxToRem(20) pxToRem(20) pxToRem(20) pxToRem(70);
    position: relative;
    border-bottom: pxToRem(3) solid $black;
    background-color: $white;
    margin-bottom: pxToRem(10);
}

.news-events__date {
    position: absolute;
    top: pxToRem(20);
    left: pxToRem(20);
}

.news-events__day {
    font-size: pxToRem(26);
    line-height: 1.15;
    font-weight: 600;
}

.news-events__month {
    font-size: pxToRem(14);
    text-transform: uppercase;
    display: block;

}

.news-events__title {
    font-size: pxToRem(18);
    font-weight: 600;
    margin: 0;
}

.news-events__link {
    text-decoration: none;
    color: $black-two;
    display: block;
}

.news-events__type {
    margin-top: pxToRem(5);
    margin-bottom: pxToRem(10);
}

.news-events__address {
    margin-top: 0;
    .news-events__location {
        padding-top: 0;
    }
}

.news-events__desc {
    line-height: 1.27;
    font-size: pxToRem(15);
    color: $greyish-brown;
    margin: 0 0 pxToRem(10);
    display: none;
}

.news-events__meta {
    display: block;
}

.news-events__location {
    font-size: pxToRem(13);
    color: $warm-grey-three;
    padding-top: pxToRem(10);
    display: block;
}

.news-events__location-label {
    font-weight: 600;
}

.news-events__location .icon-location {
    width: pxToRem(11);
    height: pxToRem(15);
    position: relative;
    top: pxToRem(3);
    margin-right: pxToRem(3);
}

.news-events__more-button {
    display: inline-block;
    margin: pxToRem(27) auto;
    text-decoration: none;
    color: $black-four;

    &.disabled {
        background-color: $white-seven;
    }
}

.news-events__more-button .icon-arrow-down {
    width: pxToRem(11);
    height: pxToRem(10);
    transform: rotate(90deg);
    margin-left: pxToRem(5);
}

.news-events__more {
    text-decoration: none;
    font-size: pxToRem(13);
    color: $black-two;
    display: block;
    text-indent: -9999px;
    width: pxToRem(30);
    height: pxToRem(30);
}

.news-events__more .icon-arrow-right {
    width: pxToRem(17);
    height: pxToRem(11);
    display: block;
    position: relative;
    top: pxToRem(-8);
}

.news-events__copy-link {
    display: none;
    text-decoration: none;
    font-size: pxToRem(13);
    color: $black-two;
    line-height: pxToRem(21);
    &:hover {
        text-decoration: underline;
    }
}

.news-events__copy-link .icon-link {
    width: pxToRem(17);
    height: pxToRem(15);
    margin-right: pxToRem(10);
    position: relative;
    top: pxToRem(3);
}

.news-events__email-link {
    text-decoration: none;
    font-size: pxToRem(13);
    color: $black-two;
    display: none;
    line-height: pxToRem(21);
    &:hover {
        text-decoration: underline;
    }
}

.news-events__email-link .icon-mail {
    width: pxToRem(19);
    height: pxToRem(14);
    margin-right: pxToRem(10);
    position: relative;
    top: pxToRem(2);
}