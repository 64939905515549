/* Module: S */
.resources-listing  {
  
  .main >h1.library__title {
    margin-top: pxToRem(10);
    margin-bottom: pxToRem(14);
  }
  
  .search__resources__sort  {
    .sq-form-question-title {
      margin-right: 20px;
      display: inline-block;
    }

    .sq-form-question-answer {
      width: auto;
    }

     .search__resources__select {
        height: auto;
        background-color: $white;
     }
  }

  .sq-form-question-title 
  .resources__list {
    .search__title {
      margin-bottom: pxToRem(15);
    }
  }
  
  .search {
    
    margin-bottom: 0;
    form {
          margin-bottom: pxToRem(15);
    }
  }

  .search__resources {

    &-wrapper {
      @include flexbox();
      @include justify-content(flex-end);
      position: relative;
      align-items: center;
      margin-top: pxToRem(17);
    }

    &-summary {
      display: inline-block; 
      margin-top: 0;
      margin-bottom: 0;
      position: absolute;
      left: 0;
      font-size: pxToRem(16);
      line-height: 1.5;
      color: $warm-grey-three;
    }

    &__sort {
      display: inline-block;
    }
  }


  .search__meta { 
    &-summary {
      margin-top: pxToRem(4);
    }

    &-date,
    &-type {
      font-size: pxToRem(15);
    }
  }
}



