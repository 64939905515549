/* Module: S */
.accordion {
    margin: 0 pxToRem(-40);
}

.accordion__item-name {
     .icon-arrow-right {
        right: pxToRem(27);
    }
}

.accordion__item-content {
    padding: pxToRem(25) pxToRem(70) pxToRem(42) pxToRem(40);
}

.accordion__item-link {
    font-size: pxToRem(18);
    line-height: pxToRem(25);
    padding: pxToRem(35) pxToRem(60) pxToRem(35) pxToRem(40);
}